// lightTheme.js
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#f3a950",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f05023",
    },
    white: {
      main: "#fff",
    },
    // ... define your light mode palette colors
  },
  typography: {
    // fontFamily: "Helvetica, Arial, sans-serif",
    // h1: {
    //   fontSize: "32px",
    //   fontWeight: 700,
    //   fontFamily: "Roboto, sans-serif",
    // },
    // h2: {
    //   fontSize: "28px",
    // },
    // h3: {
    //   fontSize: "24px",
    // },
    // h4: {
    //   fontSize: "20px",
    // },
    // h5: {
    //   fontSize: "16px",
    // },
    // h6: {
    //   fontSize: "14px",
    // },
    fontFamily: "Helvetica, Arial, sans-serif",
    h1: {
      fontSize: "2rem", // Adjusted font size for h1
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.75rem", // Adjusted font size for h2
    },
    h3: {
      fontSize: "1.5rem", // Adjusted font size for h3
    },
    h4: {
      fontSize: "1.25rem", // Adjusted font size for h4
    },
    h5: {
      fontSize: "1.125rem", // Adjusted font size for h5
    },
    h6: {
      fontSize: "1rem", // Adjusted font size for h6
    },
    subtitle1: {
      fontSize: "1.125rem",
    },
    subtitle2: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1.125rem",
    },
    body2: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "1rem",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    caption: {
      fontSize: "0.75rem",
    },
    overline: {
      fontSize: "0.75rem",
      textTransform: "uppercase",
    },
    fontSize: 16,
  },
  // Set your desired base font size

  overrides: {
    MuiButton: {
      raisedPrimary: {
        color: "white",
        boxShadow: "none",
      },
      defaultProps: {
        disableElevation: true,
      },
      label: {
        color: "#f1f1f1",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});
// Make the theme responsive
const responsiveLightTheme = responsiveFontSizes(lightTheme);

export default lightTheme;
