import React, { useEffect, useMemo, useRef, useState } from "react";
import "./HeaderSearch.css";
import { useDispatch, useSelector } from "react-redux";
import { getElasticSearch } from "../StateSlices/elasticSearchSlice";
import { Chip } from "@mui/material";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const HeaderSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (visible) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex + suggestions.length - 1) % suggestions.length
          );
        } else if (e.key === "Enter") {
          e.preventDefault();
          if (selectedIndex >= 0) {
            setSearchTerm(suggestions[selectedIndex].itemName);
            setVisible(false);
            setExpanded(false);
            if (suggestions[selectedIndex]) {
              if (suggestions[selectedIndex].isCategory)
                navigate(`/shop?category=${suggestions[selectedIndex]._id}`);
              else if (suggestions[selectedIndex].isSubCategory)
                navigate(`/shop?subcategory=${suggestions[selectedIndex]._id}`);
              else
                navigate(`/shop?search=${suggestions[selectedIndex].itemName}`);
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [visible, selectedIndex, suggestions]);

  // useEffect(() => {
  //   if (searchTerm) {
  //     setSuggestionLoading(true);
  //     dispatch(getElasticSearch(searchTerm));
  //   } else {
  //     setSuggestions([]);
  //   }
  // }, [searchTerm]);

  const { searchinfo } = useSelector((state) => state.elasticsearch);

  useEffect(() => {
    if (searchinfo && searchinfo.length > 0) {
      setSuggestions(searchinfo);
      setSuggestionLoading(false);
    } else {
      setSuggestions([]);
    }

    // return () => setRses([]);
  }, [searchinfo]);

  const handleFocus = () => {
    setVisible(true);
    setExpanded(true);
  };

  const handleBlur = () => {
    // Use setTimeout to delay hiding the suggestions box
    // This allows clicks on suggestions to be registered before the box hides
    setTimeout(() => {
      setVisible(false);
      setExpanded(false);
    }, 200);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.itemName);
    setVisible(false);
    setExpanded(false);
    // inputRef.current.value = suggestion.itemName;
    inputRef.current.focus();
    if (suggestion && suggestion.itemName) {
      if (suggestion.isGoal) navigate(`/shop?goal=${suggestion.itemName}`);
      else if (suggestion.isCategory)
        navigate(`/shop?categoryName=${suggestion.itemName}`);
      else if (suggestion.isSubCategory)
        navigate(`/shop?subCategoryName=${suggestion.itemName}`);
      else navigate(`/shop?search=${suggestion.itemName}`);
    }
  };

  const clearInput = () => {
    setSearchTerm("");
    setSuggestions([]);
    inputRef.current.focus();
  };

  const fetchSuggestions = (query) => {
    dispatch(getElasticSearch(query));
  };

  // Use useMemo to debounce the function and ensure it's stable across renders
  const debouncedFetchSuggestions = useMemo(
    () =>
      debounce((query) => {
        fetchSuggestions(query);
      }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value !== "") setSuggestionLoading(true);
    else setSuggestionLoading(false);
    debouncedFetchSuggestions(value);
  };

  return (
    <div className={`search-container ${expanded ? "expanded" : ""}`}>
      <input
        ref={inputRef}
        type="text"
        className={`search-input ${visible ? "visible" : "not-visible"}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={searchTerm}
        onChange={
          handleInputChange
          // setSearchTerm(e.target.value);
          // setSelectedIndex(-1);
        }
        placeholder="Search equipment..."
      />
      {searchTerm && (
        <button
          className="clear-button"
          onClick={clearInput}
          aria-label="Clear search"
        >
          x
        </button>
      )}
      <div className={`search-suggestions ${visible ? "visible" : ""}`}>
        {suggestionLoading ? (
          <div className="loader"></div>
        ) : (
          <ul>
            {console.log("Suggestions", suggestions)}
            {suggestions?.map((item, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(item)}
                className={selectedIndex === index ? "selected" : ""}
              >
                {item.itemName}
                {item.isGoal && (
                  <span style={{ float: "right" }}>
                    <Chip
                      size="small"
                      label="Goal"
                      sx={{
                        marginRight: ".5rem",
                        fontSize: "10px",
                        background: "var(--grey-color)",
                      }}
                    />
                    <FaExternalLinkAlt
                      //   onClick={() => {
                      //     if (item.isCategory) {
                      //       navigate("/shop?category=" + item._id);
                      //     }
                      //   }}
                      size={".75em"}
                      color="var(--grey-color)"
                    />
                  </span>
                )}
                {item.isCategory && (
                  <span style={{ float: "right" }}>
                    <Chip
                      size="small"
                      label="Category"
                      sx={{
                        marginRight: ".5rem",
                        fontSize: "10px",
                        background: "var(--grey-color)",
                      }}
                    />
                    <FaExternalLinkAlt
                      //   onClick={() => {
                      //     if (item.isCategory) {
                      //       navigate("/shop?category=" + item._id);
                      //     }
                      //   }}
                      size={".75em"}
                      color="var(--grey-color)"
                    />
                  </span>
                )}
                {item.isSubCategory && (
                  <span style={{ float: "right" }}>
                    <Chip
                      size="small"
                      label="Sub Category"
                      sx={{
                        marginRight: ".5rem",
                        fontSize: "10px",
                        background: "var(--grey-color)",
                      }}
                    />
                    <FaExternalLinkAlt
                      //   onClick={() => {
                      //     if (item.isSubCategory) {
                      //       navigate("/shop?subcategory=" + item._id);
                      //     }
                      //   }}
                      size={".75em"}
                      color="var(--grey-color)"
                    />
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default HeaderSearch;
