import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import {
  Slider,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Switch,
  Rating,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  InputBase,
  Stack,
  Chip,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import "./Filter.css";
import { Expand, ExpandCircleDown, ExpandMore, SearchRounded } from "@mui/icons-material";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import {
  categoies,
  goalOptions,
  itemGoalsNested,
} from "../../pages/AddNewItem/data";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { alpha, styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { components } from "react-select";
import { getElasticSearch } from "../StateSlices/elasticSearchSlice";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useDispatch, useSelector } from "react-redux";
import { FaExternalLinkAlt } from "react-icons/fa";

const miles = [
  {
    label: "10 Miles",
    value: "10",
  },
  {
    label: "50 Miles",
    value: "50",
  },
  {
    label: "100 Miles",
    value: "100",
  },
  {
    label: "250 Miles",
    value: "250",
  },
  {
    label: "500 Miles",
    value: "500",
  },
];

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.value.structured_formatting.main_text}
    </components.SingleValue>
  );
};

const Input = (props) => <components.Input {...props} isHidden={false} />;

const Filter = ({
  categories,
  rentPriceRange,
  setRentPriceRange,
  buyPriceRange,
  setBuyPriceRange,
  handleRentPriceChange,
  handleBuyPriceChange,
  handleCategoryChange,
  setTabValue,
  tabValue,
  categoryFilter,
  subCategoryFilter,
  brandFilter,
  setCategorySelected,
  setBrandSelected,
  dateFilter,
  setDateFilter,
  setPriceByFilter,
  priceByFilter,
  setRentPriceFilter,
  setBuyPriceFilter,
  categorySelected,
  setSubCategorySelected,
  subCategorySelected,
  valSubCategories,
  setValSubCategories,
  setValCategories,
  valCategories,
  setItemLocTemp,
  itemLocTemp,
  setLangLong,
  milesFilter,
  setMilesFilter,
  langLong,
  transportationFilter,
  setTransportationFilter,
  transportationOptions,
  brandSelected,
  search,
  setSearch,
  goalSelected,
  setGoalSelected,
  categoryNameSelected,
  setCategoryNameSelected,
  subCategoryNameSelected,
  setSubCategoryNameSelected,
  fetchFormFields,
  sizeOption,
  sizeSelected,
  setSetsizeSelected,
  inputLocValue,
  setInputLocValue,
  userInfo,
  locationSet,
  platforms,
  platformSelected,
  setPlatformSelected,
  expansions,
}) => {
  //Search
  const navigate = useNavigate();
  const inputSearchRef2 = useRef();
  const location = useLocation();
  const [search2, setSearch2] = useState(search);

  const submitSearchValue = (e) => {
    e.preventDefault();
    let search = inputSearchRef2.current.value;
    setSearch2(search);
    navigate(`/shop?search=${search}`, { state: { searchKeyword: search } });
  };
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: "var(--white-color)",
    color: "var(--black-color)",
    marginLeft: 0,
    width: "100%",
    margin: "1rem 0",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      // marginTop: "1.8rem",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 0),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(0.5, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)})`,
      borderBottom: "2px solid var(--grey-color)",
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        "&:focus": {
          width: "100%",
        },
      },
    },
  }));
  // END Search
  // const expansions = [];
  // useEffect(() => {
  //   expansions.push("panel0");
  //   const queryParams = new URLSearchParams(location.search);
  //   let goal = queryParams.get("goal");
  //   const categoryName = queryParams.get("categoryName");
  //   const subCategoryName = queryParams.get("subCategoryName");

  //   if (goal) {
  //     expansions.push("panel1");
  //     if (categoryName) {
  //       expansions.push("panelCategory");
  //       if (subCategoryName) {
  //         expansions.push("panelSubCategory");
  //       }
  //     }
  //   }
  //   if (brandSelected && brandSelected.length > 0) {
  //     expansions.push("panel2");
  //   }
  //   if (userInfo || locationSet) {
  //     expansions.push("panel5");
  //   }
  // }, []);
  const [checked, setChecked] = React.useState(true);
  const [value, setValue] = React.useState(2);
  // debugger;
  const [expanded, setExpanded] = React.useState(expansions);

  const [filterSelectedCategories, setFilterSelectedCategories] = useState([]);
  const [visibleSubCatCount, setVisibleSubCatCount] = useState(10);
  const [inputValue, setInputValue] = useState("");

  const [suggestionLoading, setSuggestionLoading] = useState(false);

  const selectRef = useRef();

  const onFocus = () => itemLocTemp && selectRef.current.inputRef.select();

  const onInputChange = (inputLocValue, { action }) => {
    // onBlur => setInputValue to last selected value
    // if (action === "input-blur") {
    //   setInputValue(value ? value.label : "");
    // }
    console.log(action);
    // onInputChange => update inputValue
    if (action === "input-change") {
      setInputLocValue(inputLocValue);
    }
  };

  useEffect(() => {
    if (expansions) {
      setExpanded(expansions);
    }
  }, [expansions]);

  // const handleChange = (panel) => (newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  const handleChange = (panel) => (newExpanded) => {
    setExpanded((prevExpanded) => {
      if (prevExpanded.includes(panel)) {
        // If the panel is already expanded, remove it from the array
        return prevExpanded.filter((expandedPanel) => expandedPanel !== panel);
      } else {
        // If the panel is not expanded, add it to the array
        return [...prevExpanded, panel];
      }
    });
  };

  const { beforeToday } = DateRangePicker;

  const resetFilter = () => {
    setCategorySelected([]);
    setBrandSelected([]);
    setTabValue(["buy", "rent"]);
    setBuyPriceRange([0, 25000]);
    setRentPriceRange([0, 25000]);
    setTransportationFilter([]);
    setDateFilter([]);
    setItemLocTemp(null);
    setMilesFilter("");
    setLangLong({});
    setSearch("");
    navigate("/shop", { replace: true });
    setGoalSelected([]);
    setCategoryNameSelected([]);
    setSubCategoryNameSelected([]);
    setDynamicCategoryOptions([]);
    setDynamicSubCategoryOptions([]);
    populateDefault();
  };

  const dispatch = useDispatch();
  const { searchinfo } = useSelector((state) => state.elasticsearch);

  useEffect(() => {
    if (searchinfo && searchinfo.length > 0) {
      setFilteredSuggestions(searchinfo);
      setSuggestionLoading(false);
    } else {
      setFilteredSuggestions([]);
    }

    // return () => setRses([]);
  }, [searchinfo]);

  const inputSearchRef1 = useRef();
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValuee, setInputValuee] = useState("");
  const [valuee, setValuee] = useState("");

  const debouncedFetchSuggestions = debounce((inputQuery) => {
    if (inputQuery.trim() !== "") {
      setSuggestionLoading(true);
      dispatch(getElasticSearch(inputQuery));
    } else {
      setFilteredSuggestions([]); // Clear suggestions if inputQuery is empty
    }
  }, 1500);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedFetchSuggestions(value);
    // dispatch(getElasticSearch(value));
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const selectedValue = inputValue.trim();
      setInputValue(selectedValue);
      if (inputSearchRef1.current) {
        inputSearchRef1.current.value = e.target.value;
        console.log("aa gaya");
        navigate(`/shop?search=${inputSearchRef1.current.value}`, {
          state: { searchKeyword: inputSearchRef1.current.value },
        });
      }
    }
  };

  const handleCategoryChangeCallback = useCallback((catId) => {
    setCategorySelected((prevSelected) => {
      if (prevSelected.includes(catId)) {
        return prevSelected.filter((id) => id !== catId);
      } else {
        return [...prevSelected, catId];
      }
    });
  }, []);

  const handleSubCategoryChangeCallback = useCallback((subCatId) => {
    setSubCategorySelected((prevSelected) => {
      if (prevSelected.includes(subCatId)) {
        return prevSelected.filter((id) => id !== subCatId);
      } else {
        return [...prevSelected, subCatId];
      }
    });
  }, []);

  const [dynamicCategoryOptions, setDynamicCategoryOptions] = useState([]);
  const [dynamicSubCategoryOptions, setDynamicSubCategoryOptions] = useState(
    []
  );
  useEffect(() => {
    console.log("Dynamic Category Options : ", dynamicCategoryOptions);
  }, [dynamicCategoryOptions]);

  const populateDefault = () => {
    const formatKeys = (nestedObject) => {
      return Object.keys(nestedObject).map((category) => {
        return Object.keys(nestedObject[category]).map((key) => ({
          label: key,
          value: key.toLowerCase().replace(/ /g, "_"),
        }));
      });
    };

    setDynamicCategoryOptions(formatKeys(itemGoalsNested).flat(2));

    const flattenKeysAndValues = (nestedObject) => {
      const flattened = [];

      Object.keys(nestedObject).forEach((category) => {
        Object.keys(nestedObject[category]).forEach((key) => {
          nestedObject[category][key].forEach((item) => {
            flattened.push({
              label: item,
              value: item,
            });
          });
        });
      });

      return flattened;
    };

    // console.log("OPOPOP",flattenKeysAndValues(itemGoalsNested));
    setDynamicSubCategoryOptions(flattenKeysAndValues(itemGoalsNested));
  };

  useEffect(() => {
    populateDefault();
  }, []);

  useEffect(() => {
    console.log("Dynamic Sub Category Options : ", dynamicSubCategoryOptions);
  }, [dynamicSubCategoryOptions]);
  const extractLabelAndValues = (type, selectedLabelArr) => {
    if (type === "category") {
      // const filteredGoal = itemGoalsNested[selectedLabel];
      const filteredGoal = [];
      selectedLabelArr.map((labl) => filteredGoal.push(itemGoalsNested[labl]));
      // console.log("SelectedLabelArr", selectedLabelArr);
      // console.log("FIltered Goal", filteredGoal);
      if (filteredGoal) {
        setDynamicCategoryOptions(
          filteredGoal
            .map((flt) =>
              Object.keys(flt).map((key) => ({
                label: key,
                value: key.toLowerCase().replace(/ /g, "_"),
              }))
            )
            .flat()
        );
        if (categoryNameSelected) {
          setCategoryNameSelected([]);
        }
        if (subCategoryNameSelected) {
          setSubCategoryNameSelected([]);
        }
        if (sizeSelected) {
          setSetsizeSelected([]);
        }
      }
    } else if (type === "subcategory") {
      const filteredCategory = [];
      console.log(
        "OPOPOPOPOP",
        selectedLabelArr,
        itemGoalsNested,
        goalSelected
      );

      goalSelected.map((g) => {
        selectedLabelArr
          .map((s) => {
            if (itemGoalsNested[g][s]) {
              filteredCategory.push(itemGoalsNested[g][s]);
            }
          })
          .flat(2);
      });

      if (goalSelected.length == 0 && selectedLabelArr.length > 0) {
        Object.keys(itemGoalsNested).map((goal) => {
          // For each goal, loop through selected labels
          selectedLabelArr.map((label) => {
            // Check if the goal has the selected label
            if (itemGoalsNested[goal][label]) {
              // If it exists, push the array to filteredCategory
              filteredCategory.push(...itemGoalsNested[goal][label]);
            }
          });
        });

        console.log("OPOPOPOPOP", filteredCategory);
      }

      if (filteredCategory) {
        const filteredCategoryFlat = filteredCategory.flat();
        // console.log(
        //   "FILTEREd Category",
        //   filteredCategoryFlat.map((item) => ({
        //     label: item,
        //     value: item,
        //   }))
        // );
        setDynamicSubCategoryOptions(
          filteredCategoryFlat.map((item) => ({
            label: item,
            value: item,
          }))
        );
      }
      if (subCategoryNameSelected) {
        setSubCategoryNameSelected([]);
      }
      if (sizeSelected) {
        setSetsizeSelected([]);
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let goal = queryParams.get("goal");
    const categoryName = queryParams.get("categoryName");
    const subCategoryName = queryParams.get("subCategoryName");

    if (goal) {
      // setGoalSelected(goal)
      // extractLabelAndValues("category", goal)
      if (goal === "Lifting People and Material")
        goal = "Lifting People & Material";
      const filteredGoal = itemGoalsNested[goal];
      if (filteredGoal) {
        setDynamicCategoryOptions(
          Object.keys(filteredGoal).map((key) => ({
            label: key,
            value: key.toLowerCase().replace(/ /g, "_"),
          }))
        );
      }
    }
    if (goal && categoryName) {
      // setCategoryNameSelected([categoryName])
      // extractLabelAndValues("subcategory", categoryName)
      if (goal === "Lifting People and Material")
        goal = "Lifting People & Material";
      console.log(goal);
      const filteredCategory =
        itemGoalsNested[goal][categoryName] ||
        itemGoalsNested[goal]?.categoryName ||
        "";
      console.log("here it came", filteredCategory);
      if (filteredCategory) {
        setDynamicSubCategoryOptions(
          filteredCategory.map((item) => ({
            label: item,
            value: item.toLowerCase().replace(/ /g, "_"),
          }))
        );
      }
    }
  }, [location]);

  const platformFilter = [
    {
      label: "Dizel",
      _id: "1234",
    },
    {
      label: "United",
      _id: "1235",
    },
    {
      label: "Sunbelt",
      _id: "1236",
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is small
  const [expandedFilter, setExpandedFilter] = useState(!isMobile); // Expand by default on larger screens

  const handleChangeFilterAccordion = (panel) => (event, isExpanded) => {
    setExpandedFilter(isExpanded ? panel : false);
  };

  return (
    <div className="filter-container">
      <FormControl
        sx={{ maxWidth: { md: "300px", xs: "100%" }, width: "100%" }}
      >
        <Accordion
        expanded={isMobile ? expandedFilter : true} // Always expanded on mobile
        onChange={handleChangeFilterAccordion('filteracc')}
        sx={{ width: '100%', border: "none", boxShadow: "none" }}
        elevation={0} 
      >
        <AccordionSummary
          expandIcon={isMobile && <ExpandCircleDown />}
          aria-controls="filteracc-content"
          id="filteracc"
          sx={{justifyContent: "space-between", padding: 0}}
        >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h3" fontWeight={700}>
            Filters
          </Typography>
          {/* {console.log(
            "filtersss",
            tabValue,
            categorySelected,
            brandSelected,
            buyPriceRange,
            rentPriceRange,
            transportationFilter,
            dateFilter,
            itemLocTemp,
            miles,
            milesFilter
          )} */}

          {categorySelected.length > 0 ||
          subCategorySelected.length > 0 ||
          (tabValue.length > 0 && tabValue.length < 2) ||
          brandSelected.length > 0 ||
          buyPriceRange[0] > 1 ||
          buyPriceRange[1] < 25000 ||
          rentPriceRange[0] > 1 ||
          rentPriceRange[1] < 25000 ||
          transportationFilter.length > 0 ||
          dateFilter.length > 0 ||
          itemLocTemp ||
          search ||
          milesFilter !== "" ||
          goalSelected.length > 0 ||
          categoryNameSelected.length > 0 ||
          platformSelected.length > 0 ||
          subCategoryNameSelected.length > 0 ? (
            <Typography
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer", position: "absolute", right: isMobile ? "2rem":"1rem" }}
              variant="body2"
              onClick={resetFilter}
            >
              <span style={{ marginRight: ".5rem",  }}>Reset</span>{" "}
              <FaArrowRotateLeft />
            </Typography>
          ) : (
            ""
          )}
        </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{padding: 0}}>
          
       
        {/* <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h3" fontWeight={700}>
            Filters
          </Typography>
          
        

          {categorySelected.length > 0 ||
          subCategorySelected.length > 0 ||
          (tabValue.length > 0 && tabValue.length < 2) ||
          brandSelected.length > 0 ||
          buyPriceRange[0] > 1 ||
          buyPriceRange[1] < 25000 ||
          rentPriceRange[0] > 1 ||
          rentPriceRange[1] < 25000 ||
          transportationFilter.length > 0 ||
          dateFilter.length > 0 ||
          itemLocTemp ||
          search ||
          milesFilter !== "" ||
          goalSelected.length > 0 ||
          categoryNameSelected.length > 0 ||
          platformSelected.length > 0 ||
          subCategoryNameSelected.length > 0 ? (
            <Typography
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              variant="body2"
              onClick={resetFilter}
            >
              <span style={{ marginRight: ".5rem" }}>Reset</span>{" "}
              <FaArrowRotateLeft />
            </Typography>
          ) : (
            ""
          )}
        </Stack> */}
        <Divider sx={{ margin: ".5rem 0" }} />
        {/* <form class="search" onSubmit={(e) => submitSearchValue(e)}>
          <Search sx={{ borderRadius: "5px" }}>
            <SearchIconWrapper>
              <SearchRounded
                sx={{ fontSize: "24px", color: "var(--grey-color)" }}
              />
            </SearchIconWrapper>
            <StyledInputBase
              // value={search2}
              inputRef={inputSearchRef2}
              // onChange={(e) => setSearch2(e.target.value)}
              placeholder="Search Equipment…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Button type="submit" sx={{ display: "none" }}>
            Submit{" "}
          </Button>
        </form> */}
        <Autocomplete
          value={valuee}
          onChange={(event, newValue) => {
            // console.log("valuee", valuee, newValue);
            setValuee(newValue);
            if (newValue && newValue.itemName) {
              if (newValue.isCategory)
                navigate(`/shop?category=${newValue._id}`);
              else if (newValue.isSubCategory)
                navigate(`/shop?subcategory=${newValue._id}`);
              else navigate(`/shop?search=${newValue.itemName}`);
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            // console.log("input valuee", newInputValue);
            inputSearchRef1.current.value = newInputValue;
            setInputValue(newInputValue);
            // navigate(`/shop?search=${newInputValue}`, {
            //   state: { searchKeyword: newInputValue },
            // });
          }}
          size="small"
          options={filteredSuggestions}
          getOptionLabel={(option) => (option ? option.itemName : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              ref={inputSearchRef1}
              onChange={(e) => handleInputChange(e)}
              label=""
              margin="normal"
              variant="standard"
              sx={{
                // m: 1,
                // width: "",
                color: "var(--white-color)",
                background: "transparent",
                borderRadius: "5px",
                border: "none",
                "& input": {
                  color: "var(--black-color)", // Set input text color to grey
                  border: "none",
                  outline: "none",
                },
                fontFamily: "Helvetica,Arial,sans-serif",
                fontSize: "1.125rem",
                "::placeholder": {
                  fontSize: "14px", // Set placeholder font size to 14px
                  fontFamily: "Helvetica, Arial, sans-serif", // Set placeholder font family
                },
              }}
              placeholder="Search equipment..."
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {suggestionLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.itemName, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.itemName, matches);
            console.log(props);

            return (
              <li
                {...props}
                // onKeyDown={(e) => handleEnterKeyPress(e)}
                style={{
                  background: "transparent",
                  color: "var(--grey-color)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
                <div>
                  {option.isCategory && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Chip
                        size="small"
                        label="Category"
                        sx={{ marginRight: ".5rem", fontSize: "12px" }}
                      />
                      <FaExternalLinkAlt
                        onClick={() => {
                          if (option.isCategory) {
                            navigate("/shop?category=" + option._id);
                          }
                        }}
                        size={".75em"}
                        color="var(--grey-color)"
                      />
                    </div>
                  )}
                  {option.isSubCategory && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Chip
                        size="small"
                        label="Sub Category"
                        sx={{ marginRight: ".5rem", fontSize: "12px" }}
                      />
                      <FaExternalLinkAlt
                        onClick={() => {
                          if (option.isSubCategory) {
                            navigate("/shop?subcategory=" + option._id);
                          }
                        }}
                        size={".75em"}
                        color="var(--grey-color)"
                      />
                    </div>
                  )}
                </div>
              </li>
            );
          }}
          noOptionsText={
            filteredSuggestions.length === 0
              ? "Please type in to get suggestions..."
              : null
          }
          sx={{
            "::placeholder": {
              fontSize: "14px", // Set placeholder font size to 14px
              fontFamily: "Helvetica, Arial, sans-serif", // Set placeholder font family
            },
          }}
          //   value={inputValue}
        />
        <Accordion
          sx={{
            boxShadow: "none",
            padding: ".5rem 0",
            borderBottom: "1px solid var(--grey-color)",
          }}
          disableGutters
          // sx={{ marginTop: "1rem" }}
          expanded={expanded.includes("panel0")}
          onChange={handleChange("panel0")}
        >
          <AccordionSummary
            sx={{ padding: 0 }}
            expandIcon={<ExpandMore />}
            aria-controls="panel0a-content"
            id="panel0a-header"
          >
            <Typography variant="body1" fontWeight={500}>
              Rent/Buy
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="filter-acc-details"
            sx={{
              paddingBottom: "1rem",
            }}
          >
            <FormGroup sx={{ marginBottom: "1rem" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tabValue.includes("buy") ? true : false}
                    onChange={(e) =>
                      setTabValue((prevState) => {
                        if (prevState.includes("buy")) {
                          return prevState.filter((item) => item !== "buy");
                        } else {
                          return [...prevState, "buy"];
                        }
                      })
                    }
                  />
                }
                label={
                  <Typography className="filter-options-label">
                    Buy Equipment
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tabValue.includes("rent") ? true : false}
                    onChange={(e) =>
                      setTabValue((prevState) => {
                        if (prevState.includes("rent")) {
                          return prevState.filter((item) => item !== "rent");
                        } else {
                          return [...prevState, "rent"];
                        }
                      })
                    }
                  />
                }
                label={
                  <Typography className="filter-options-label">
                    Rental Equipment
                  </Typography>
                }
              />
            </FormGroup>

            {tabValue.includes("rent") && (
              <>
                {/* RENT PRICE SLIDER */}
                <Typography
                  variant="body1"
                  className="filter-options-label"
                  fontWeight={500}
                  marginTop={"1rem"}
                >
                  Rent Price
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={priceByFilter}
                  exclusive
                  size="small"
                  onChange={(e, nextVal) => {
                    if (nextVal != null) setPriceByFilter(nextVal);
                  }}
                  aria-label="Price By Filter"
                >
                  <ToggleButton
                    sx={{
                      textTransform: "capitalize",
                      letterSpacing: "normal",
                      padding: ".5rem 1rem",
                    }}
                    size="small"
                    value="itemDailyPrice"
                  >
                    Daily
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      textTransform: "capitalize",
                      letterSpacing: "normal",
                      padding: ".5rem 1rem",
                    }}
                    size="small"
                    value="itemWeeklyPrice"
                  >
                    Weekly
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      textTransform: "capitalize",
                      letterSpacing: "normal",
                      padding: ".5rem 1rem",
                    }}
                    size="small"
                    value="itemMonthlyPrice"
                  >
                    Monthly
                  </ToggleButton>
                </ToggleButtonGroup>
                <Slider
                  sx={{
                    "& .MuiSlider-thumb": {
                      width: 10,
                      height: 10,
                      boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  min={0}
                  max={25000}
                  getAriaLabel={() => "Minimum distance"}
                  valueLabelFormat={(value) => `$${value}`}
                  value={rentPriceRange}
                  onChange={handleRentPriceChange}
                  valueLabelDisplay="auto"
                  disableSwap
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    value={rentPriceRange[0]}
                    onChange={(e) =>
                      handleRentPriceChange(
                        e,
                        [e.target.value, rentPriceRange[1]],
                        0
                      )
                    }
                    className="filter-slider-btn"
                    type="number"
                  />
                  <input
                    value={rentPriceRange[1]}
                    onChange={(e) =>
                      handleRentPriceChange(
                        e,
                        [rentPriceRange[0], e.target.value],
                        1
                      )
                    }
                    className="filter-slider-btn"
                    type="number"
                  />
                </Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ marginBottom: "1rem" }}
                  onClick={() => {
                    setRentPriceFilter((prev) => !prev);
                  }}
                >
                  Set Rent Price
                </Button>
                {/* END RENT PRICE SLIDER */}
              </>
            )}
            {tabValue.includes("buy") && (
              <>
                {/* BUY PRICE SLIDER */}
                <Typography
                  variant="body1"
                  className="filter-options-label"
                  fontWeight={500}
                  marginTop={"1rem"}
                >
                  Buy Price
                </Typography>
                <Slider
                  sx={{
                    "& .MuiSlider-thumb": {
                      height: 10,
                      width: 10,
                    },
                  }}
                  min={0}
                  max={25000}
                  getAriaLabel={() => "Minimum distance"}
                  valueLabelFormat={(value) => `$${value}`}
                  value={buyPriceRange}
                  onChange={handleBuyPriceChange}
                  valueLabelDisplay="auto"
                  disableSwap
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    value={buyPriceRange[0]}
                    onChange={(e) =>
                      handleBuyPriceChange(
                        e,
                        [e.target.value, buyPriceRange[1]],
                        0
                      )
                    }
                    className="filter-slider-btn"
                    type="number"
                  />
                  <input
                    value={buyPriceRange[1]}
                    onChange={(e) =>
                      handleBuyPriceChange(
                        e,
                        [buyPriceRange[0], e.target.value],
                        1
                      )
                    }
                    className="filter-slider-btn"
                    type="number"
                  />
                </Box>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setBuyPriceFilter((prev) => !prev);
                  }}
                >
                  Set Buy Price
                </Button>
                {/* END BUY PRICE SLIDER */}
              </>
            )}
          </AccordionDetails>
        </Accordion>

        {/* OLD CATEGORIES NESTED CODE */}
        {/* <Accordion
          disableGutters
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            boxShadow: "none",
            padding: ".5rem 0",

            borderBottom: "1px solid var(--grey-color)",
          }}
        >
          <AccordionSummary
            sx={{ padding: 0 }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body1" fontWeight={500}>
              Categories
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="filter-acc-details"
            sx={{ paddingBottom: "1rem" }}
          >
            <FormGroup>
              {categoryFilter.map((cat) => (
                <div key={cat._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categorySelected.includes(cat._id)}
                        onChange={() => handleCategoryChangeCallback(cat._id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      <Typography className="filter-options-label">
                        {cat.label}
                      </Typography>
                    }
                  />
                  {categorySelected &&
                    categorySelected.length > 0 &&
                    categorySelected[categorySelected.length - 1] ===
                      cat._id && (
                      <Box
                        data-aos="fade-up"
                        data-aos-duration="500"
                        sx={{ display: "flex", flexDirection: "column", ml: 3 }}
                      >
                        {console.log(subCategoryFilter)}
                        <FormGroup>
                          {subCategoryFilter
                            .slice(0, visibleSubCatCount)
                            .map((subcat) => (
                              <div key={subcat._id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={subCategorySelected.includes(
                                        subcat._id
                                      )}
                                      onChange={() =>
                                        handleSubCategoryChangeCallback(
                                          subcat._id
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography className="filter-options-label">
                                      {subcat.label}
                                    </Typography>
                                  }
                                />
                              </div>
                            ))}
                          {visibleSubCatCount < subCategoryFilter.length ? (
                            <Typography
                              onClick={() =>
                                setVisibleSubCatCount((prev) =>
                                  prev < subCategoryFilter.length - 10
                                    ? prev + 10
                                    : subCategoryFilter.length
                                )
                              }
                              variant="h6"
                              sx={{
                                cursor: "pointer",
                                color: "var(--primary-color)",
                              }}
                            >
                              Load more...
                            </Typography>
                          ) : (
                            <Typography
                              onClick={() => setVisibleSubCatCount(10)}
                              variant="h6"
                              sx={{
                                cursor: "pointer",
                                color: "var(--grey-color)",
                              }}
                            >
                              Collapse
                            </Typography>
                          )}
                        </FormGroup>
                      </Box>
                    )}
                </div>
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion> */}
        {/* OLD CATEGORIES NESTED CODE  END*/}
        <Accordion
          disableGutters
          expanded={expanded.includes("panel1")}
          onChange={handleChange("panel1")}
          sx={{
            boxShadow: "none",
            padding: ".5rem 0",

            borderBottom: "1px solid var(--grey-color)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0 }}
          >
            <Typography variant="body1" fontWeight={500}>
              Category
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="filter-acc-details"
            sx={{ paddingBottom: "1rem" }}
          >
            <FormGroup>
              {goalOptions.map((goal, ind) => (
                <FormControlLabel
                  key={ind}
                  control={
                    <Checkbox
                      checked={goalSelected.includes(goal.label)}
                      onChange={(e) => {
                        const isSelected = goalSelected.includes(goal.label);
                        if (isSelected) {
                          const updatedGoals = goalSelected.filter(
                            (item) => item !== goal.label
                          );
                          if (updatedGoals.length === 0) {
                            setGoalSelected([]);
                            setCategoryNameSelected([]);
                            setSubCategoryNameSelected([]);
                            setDynamicCategoryOptions([]);

                            setDynamicSubCategoryOptions([]);
                            populateDefault();
                          } else {
                            setGoalSelected(updatedGoals);
                            extractLabelAndValues("category", updatedGoals);
                            // setExpanded("panelCategory");
                          }
                        } else {
                          const updatedGoals = [...goalSelected, goal.label];
                          setGoalSelected(updatedGoals);
                          extractLabelAndValues("category", updatedGoals);
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Typography className="filter-options-label">
                      {goal.label}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        {dynamicCategoryOptions.length > 0 && (
          <Accordion
            disableGutters
            expanded={expanded.includes("panelCategory")}
            onChange={handleChange("panelCategory")}
            sx={{
              boxShadow: "none",
              padding: ".5rem 0",

              borderBottom: "1px solid var(--grey-color)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panelCategory-content"
              id="panelCategory-header"
              sx={{ padding: 0 }}
            >
              <Typography variant="body1" fontWeight={500}>
                Sub-category
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              className="filter-acc-details"
              sx={{ paddingBottom: "1rem" }}
            >
              <FormGroup>
                {dynamicCategoryOptions.map((option, ind) => (
                  <FormControlLabel
                    key={ind}
                    control={
                      <Checkbox
                        checked={categoryNameSelected.includes(option.label)}
                        onChange={(e) => {
                          const isSelected = categoryNameSelected.includes(
                            option.label
                          );
                          if (isSelected) {
                            const updateCatValues = categoryNameSelected.filter(
                              (cat) => cat !== option.label
                            );

                            if (updateCatValues.length === 0) {
                              setCategoryNameSelected([]);
                              setSubCategoryNameSelected([]);
                              setDynamicSubCategoryOptions([]);
                              populateDefault();
                            } else {
                              setCategoryNameSelected(updateCatValues);
                              extractLabelAndValues(
                                "subcategory",
                                updateCatValues
                              );
                            }
                          } else {
                            const updateCatValues = [
                              ...categoryNameSelected,
                              option.label,
                            ];
                            setCategoryNameSelected(updateCatValues);
                            extractLabelAndValues(
                              "subcategory",
                              updateCatValues
                            );
                          }
                          // console.log(updateGoalValues);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      <Typography className="filter-options-label">
                        {option.label}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        )}
        {dynamicSubCategoryOptions.length > 0 && (
          <Accordion
            disableGutters
            expanded={expanded.includes("panelSubCategory")}
            onChange={handleChange("panelSubCategory")}
            sx={{
              boxShadow: "none",
              padding: ".5rem 0",

              borderBottom: "1px solid var(--grey-color)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panelSubCategory-content"
              id="panelSubCategory-header"
              sx={{ padding: 0 }}
            >
              <Typography variant="body1" fontWeight={500}>
                Type
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              className="filter-acc-details"
              sx={{ paddingBottom: "1rem" }}
            >
              <FormGroup>
                {dynamicSubCategoryOptions.map((option, ind) => (
                  <FormControlLabel
                    key={ind}
                    control={
                      <Checkbox
                        checked={subCategoryNameSelected.includes(option.label)}
                        onChange={(e) => {
                          const isSelected = subCategoryNameSelected.includes(
                            option.label
                          );
                          if (isSelected) {
                            const updateSubCatValues =
                              subCategoryNameSelected.filter(
                                (cat) => cat !== option.label
                              );

                            if (updateSubCatValues.length === 0) {
                              setSubCategoryNameSelected([]);
                            } else {
                              setSubCategoryNameSelected(updateSubCatValues);
                              fetchFormFields(
                                goalSelected,
                                categoryNameSelected,
                                updateSubCatValues
                              );
                            }
                          } else {
                            const updateSubCatValues = [
                              ...subCategoryNameSelected,
                              option.label,
                            ];
                            setSubCategoryNameSelected(updateSubCatValues);
                            fetchFormFields(
                              goalSelected,
                              categoryNameSelected,
                              updateSubCatValues
                            );
                          }

                          if (sizeSelected) {
                            setSetsizeSelected([]);
                          }
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      <Typography className="filter-options-label">
                        {option.label}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        )}
        {goalSelected &&
          categoryNameSelected.length > 0 &&
          subCategoryNameSelected.length > 0 &&
          sizeOption.length > 0 && (
            <Accordion
              disableGutters
              expanded={expanded.includes("panelSize")}
              onChange={handleChange("panelSize")}
              sx={{
                boxShadow: "none",
                padding: ".5rem 0",

                borderBottom: "1px solid var(--grey-color)",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panelSubCategory-content"
                id="panelSubCategory-header"
                sx={{ padding: 0 }}
              >
                <Typography variant="body1" fontWeight={500}>
                  Size
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                className="filter-acc-details"
                sx={{ paddingBottom: "1rem" }}
              >
                <FormGroup>
                  {sizeOption.map((option, ind) => (
                    <FormControlLabel
                      key={ind}
                      control={
                        <Checkbox
                          checked={sizeSelected.includes(option)}
                          onChange={(e) => {
                            const isSelected = sizeSelected.includes(option);
                            const updateCatValues = isSelected ? "" : option;
                            // console.log(updateGoalValues);
                            setSetsizeSelected(updateCatValues);
                            // if(updateCatValues){
                            //   let gSelected = goalSelected
                            //   if(gSelected === "Lifting People and Material") gSelected = "Lifting People & Material"
                            //   fetchFormFields(
                            //     gSelected,
                            //     categoryNameSelected,
                            //     updateCatValues
                            //   )
                            // }
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={
                        <Typography className="filter-options-label">
                          {option}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
        <Accordion
          disableGutters
          // sx={{ marginTop: "1rem" }}
          expanded={expanded.includes("panel2")}
          onChange={handleChange("panel2")}
          sx={{
            boxShadow: "none",
            padding: ".5rem 0",
            borderBottom: "1px solid var(--grey-color)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ padding: 0 }}
          >
            <Typography variant="body1" fontWeight={500}>
              Brands
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="filter-acc-details"
            sx={{ paddingBottom: "1rem" }}
          >
            <FormGroup>
              {brandFilter.map((cat) => (
                <FormControlLabel
                  key={cat._id}
                  control={
                    <Checkbox
                      checked={brandSelected.includes(cat._id)}
                      onChange={(e) => {
                        const isSelected = brandSelected.includes(cat._id);
                        const updatedCategoriesValues = isSelected
                          ? brandSelected.filter((id) => id !== cat._id)
                          : [...brandSelected, cat._id];

                        setBrandSelected(updatedCategoriesValues);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Typography className="filter-options-label">
                      {cat.label}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          // sx={{ marginTop: "1rem" }}
          expanded={expanded.includes("panel3")}
          onChange={handleChange("panel3")}
          sx={{
            boxShadow: "none",
            padding: ".5rem 0",
            borderBottom: "1px solid var(--grey-color)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ padding: 0 }}
          >
            <Typography variant="body1" fontWeight={500}>
              Platform
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="filter-acc-details"
            sx={{ paddingBottom: "1rem" }}
          >
            <FormGroup>
              {platforms.map((cat) => (
                <FormControlLabel
                  key={cat}
                  control={
                    <Checkbox
                      checked={platformSelected.includes(cat)}
                      onChange={(e) => {
                        const isSelected = platformSelected.includes(cat);
                        const updatedCategoriesValues = isSelected
                          ? platformSelected.filter((id) => id !== cat)
                          : [...platformSelected, cat];

                        setPlatformSelected(updatedCategoriesValues);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Typography className="filter-options-label">
                      {cat}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion
          disableGutters
          sx={{
            boxShadow: "none",
            padding: ".5rem 0",
            borderBottom: "1px solid var(--grey-color)",
          }}
          // sx={{ marginTop: "1rem" }}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{ padding: 0 }}
          >
            <Typography variant="body1" fontWeight={500}>
              Transportation
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="filter-acc-details"
            sx={{ paddingBottom: "1rem" }}
          >
            <FormGroup>
              {transportationOptions.map((cat) => (
                <FormControlLabel
                  key={cat.value}
                  control={
                    <Checkbox
                      checked={transportationFilter.includes(cat.value)}
                      onChange={(e) => {
                        const isSelected = transportationFilter.includes(
                          cat.value
                        );
                        const updatedTransportationValues = isSelected
                          ? transportationFilter.filter(
                              (val) => val !== cat.value
                            )
                          : [...transportationFilter, cat.value];

                        setTransportationFilter(updatedTransportationValues);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Typography className="filter-options-label">
                      {cat.label}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion> */}

        {/* <Autocomplete
          multiple
          size="small"
          id="tags-outlined"
          options={transportationOptions}
          filterSelectedOptions
          onChange={(e, value) => {
            // console.log(value);
            const transportationArr = value.map((item) => item.value);
            setTransportationFilter(transportationArr);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              // label="Filter By Category"
              placeholder="Transportation"
            />
          )}
        /> */}

        <Accordion
          disableGutters
          sx={{
            boxShadow: "none",
            padding: ".5rem 0",
            borderBottom: "1px solid var(--grey-color)",
          }}
          expanded={expanded.includes("panel4")}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            sx={{ padding: 0 }}
          >
            <Typography variant="body1" fontWeight={500}>
              Date Range
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="filter-acc-details"
            sx={{ paddingBottom: "1rem" }}
          >
            <DateRangePicker
              size="small"
              style={{ width: "100%" }}
              placeholder="Select Date Range : From ~ To"
              disabledDate={beforeToday()}
              // onOk={(newValue) => {
              //   setValue(newValue);
              //   setFieldValue("itemLease", [
              //     ...values.itemLease,
              //     {
              //       from: newValue[0],
              //       to: newValue[1],
              //     },
              //   ]);
              // }}
              onOk={(newValue) => setDateFilter(newValue)}
              onClean={(val) => {
                setDateFilter([]);
              }}
              // value={field.initVal}
              // value={value}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="last-accordion"
          disableGutters
          sx={{
            boxShadow: "none",
            padding: ".5rem 0",
            borderBottom: "1px solid var(--grey-color)",
          }}
          expanded={expanded.includes("panel5")}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            sx={{
              padding: 0,
              borderRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Typography variant="body1" fontWeight={500}>
              Location
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="filter-acc-details"
            sx={{ paddingBottom: "1rem" }}
          >
            <GooglePlacesAutocomplete
              selectProps={{
                ref: selectRef,
                value: itemLocTemp,
                onChange: (val) => {
                  console.log(val);
                  if (val) {
                    setItemLocTemp(val);
                    setInputLocValue(
                      val ? val.value.structured_formatting.main_text : ""
                    );
                    geocodeByAddress(val.label)
                      .then((results) => getLatLng(results[0]))
                      .then(({ lat, lng }) => {
                        console.log("Successfully got latitude and longitude", {
                          lat,
                          lng,
                        });
                        setLangLong({ lng, lat });
                      });
                  }

                  // setFieldValue("itemLocation", val.label);
                },
                styles: {
                  input: (provided) => ({
                    ...provided,
                    padding: 10,
                    border: "none",
                  }),
                  option: (provided) => ({
                    ...provided,
                    zIndex: 999,
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                  }),
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                },
                inputValue: inputLocValue,
                onFocus: onFocus,
                isClearable: true,
                onInputChange: onInputChange,
                controlShouldRenderValue: false,
                components: { SingleValue, Input },
                placeholder: "Enter Location",
              }}
              apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
              apiOptions={{ region: "US" }}
            />

            <Autocomplete
              size="small"
              id="tags-outlined"
              options={miles}
              disabled={Object.keys(langLong).length > 0 ? false : true}
              // getOptionLabel={(option) => option.label}
              value={{ label: `${milesFilter} Miles`, value: milesFilter }}
              onChange={(e, value) => {
                if (value) setMilesFilter(value.value);
                else {
                  setMilesFilter("");
                }
              }}
              oncl
              style={{
                marginTop: "0.5rem",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //   label="Filter By Category"
                  placeholder="Within Miles"
                />
              )}
            />
          </AccordionDetails>
        </Accordion>
        </AccordionDetails>
        </Accordion>
      </FormControl>
    </div>
  );
};

export default Filter;
