import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  products: [],
  productCount: 0,
  error: null,
  filters: null,
  userList: [],
  remainingItems:[],
};

export const getallProducts = createAsyncThunk(
  "product/getallproducts",
  async (
    {
      filters = "",
      tab,
      categories,
      subcategories,
      brands,
      page,
      date,
      priceFilterType,
      minRentPriceFilter,
      maxRentPriceFilter,
      minBuyPriceFilter,
      maxBuyPriceFilter,
      langLong,
      milesFilter,
      transportationFilter,
      sortFilter,
      search,
      goal,
      categoryName,
      subCategory,
      size,
      platforms
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(
        `/api/product/getallproducts?tab=${tab}&categories=${categories}&subcategories=${subcategories}&brands=${brands}&page=${page}&date=${date}&priceType=${priceFilterType}&rentPriceRange=${
          minRentPriceFilter + "," + maxRentPriceFilter
        }&buyPriceRange=${
          minBuyPriceFilter + "," + maxBuyPriceFilter
        }&latlong=${Object.values(langLong).join(
          ","
        )}&miles=${milesFilter}&transportation=${transportationFilter}&goal=${goal}&categoryName=${categoryName}&subCategoryName=${subCategory}&sort=${sortFilter}&search=${search}&size=${size}&platforms=${platforms}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const allProductsSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset(state, action) {
      state.error = null;
      state.products = [];
      state.status = "idle";
      state.filters = null;
      state.remainingItems = [];
    },
    resetError(state, action) {
      state.error = null;
    },
  },
  extraReducers: {
    [getallProducts.pending]: (state, action) => {
      state.status = "loading";
    },
    [getallProducts.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.products = action.payload.products;
      state.productCount = action.payload.productCount;
      state.filters = action.payload.filters;
      state.remainingItems = action.payload.remainingItems;
      // state.filters = action.payload.filters;
      // state.userList = action.payload.userList;
    },
    [getallProducts.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { reset, resetError } = allProductsSlice.actions;
export default allProductsSlice.reducer;
