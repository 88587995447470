import { Box, Typography } from "@mui/material";
import React from "react";

const PageHeader = ({ title, desc }) => {
  return (
    <Box
      component={"div"}
      className="page-header"
      // sx={{ marginTop: 2, marginLeft: 2 }}
    >
      <Typography variant="h1" className="title mont-title">
        {title}
      </Typography>
      <Typography variant="p" color={"text.secondary"} className="desc">
        {desc}
      </Typography>
    </Box>
  );
};

export default PageHeader;
