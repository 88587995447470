import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <Container
      maxWidth="md"
      sx={{ textAlign: "justify", marginBottom: "5rem" }}
    >
      <Box data-aos="fade-in" data-aos-duration={500}>
        <Typography
          variant="h1"
          textAlign={"center"}
          fontWeight={700}
          component="h1"
          gutterBottom
          marginTop={"2rem"}
        >
          Terms and Conditions
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          margin={"1rem 0"}
          gutterBottom
        >
          Last modified: June 1, 2024
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Acceptance of the Terms of Use</u>
        </Typography>

        <Typography variant="body1" paragraph>
          These terms of use are entered into by and between You and Dizel, Inc.{" "}
          <strong>(“Company,” “we,” or “us”)</strong>. The following terms and
          conditions, together with any documents they expressly incorporate by
          reference (collectively, <strong>“Terms of Use”</strong>), govern your
          access to and use of www.godizel.com, including any content,
          functionality and services offered on or through www.godizel.com (the{" "}
          <strong>“Website”</strong>), whether as a guest or a registered user.
        </Typography>

        <Typography variant="body1" paragraph>
          Please read the Terms of Use carefully before you start to use the
          Website.{" "}
          <strong>
            By using the Website, you accept and agree to be bound and abide by
            these Terms of Use and our Privacy Policy, found at{" "}
            <Link
              to={"/privacy-policy"}
              style={{ color: "blue", borderBottom: "1px solid blue" }}
              target="_blank"
            >
              privacy-policy
            </Link>
            , incorporated herein by reference
          </strong>
          . If you do not want to agree to these Terms of Use or the Privacy
          Policy, you must not access or use the Website.
        </Typography>

        <Typography variant="body1" paragraph>
          This Website is offered and available to users who are 18 years of age
          or older. By using this Website, you represent and warrant that you
          are of legal age to form a binding contract with the Company and meet
          all of the foregoing eligibility requirements. If you do not meet all
          of these requirements, you must not access or use the Website.
        </Typography>

        {/* Subtitle 2 */}
        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Changes to the Terms of Use</u>
        </Typography>

        <Typography variant="body1" paragraph>
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them and apply to all access to and use of the Website thereafter.
        </Typography>

        <Typography variant="body1" paragraph>
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page frequently so you are aware of any
          changes, as they are binding on you.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Accessing the Website and Security</u>
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users.
        </Typography>

        <Typography variant="body1" paragraph>
          You are responsible for both:
        </Typography>

        <ul>
          <li>
            Making all arrangements necessary for you to have access to the
            Website.
          </li>
          <li>
            Ensuring that all persons who access the Website through your
            internet connection are aware of these Terms of Use and comply with
            them.
          </li>
        </ul>
        <br />
        <Typography variant="body1" paragraph>
          To access the Website or some of the resources it offers, you may be
          asked to provide certain information. It is a condition of your use of
          the Website that all the information you provide on the Website is
          correct, current and complete. You agree that all information you
          provide to register with this Website or otherwise, including, but not
          limited to, through the use of any interactive features on the
          Website, is governed by our Privacy Policy, and you consent to all
          actions we take with respect to your information consistent with our
          Privacy Policy.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Intellectual Property Rights</u>
        </Typography>

        <Typography variant="body1" paragraph>
          The Website and its entire contents, features and functionality
          (including but not limited to all information, software, text,
          displays, images, video and audio, and the design, selection and
          arrangement thereof), are owned by the Company, its licensors or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret and other
          intellectual property or proprietary rights laws.
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use permit you to use the Website for your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store or transmit any of the material on our
          Website. You must not access or use for any commercial purposes any
          part of the Website or any services or materials available through the
          Website.
        </Typography>

        <Typography variant="body1" paragraph>
          No right, title or interest in or to the Website or any content on the
          Website is transferred to you, and all rights not expressly granted
          are reserved by the Company. Any use of the Website not expressly
          permitted by these Terms of Use is a breach of these Terms of Use and
          may violate copyright, trademark and other laws.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Trademarks</u>
        </Typography>

        <Typography variant="body1" paragraph>
          The term “DIZEL”, the Company logo and all related names, logos,
          product and service names, designs and slogans are trademarks of the
          Company or its affiliates or licensors. You must not use such marks
          without the prior written permission of the Company. All other names,
          logos, product and service names, designs and slogans on this Website
          are the trademarks of their respective owners.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Prohibited Uses</u>
        </Typography>

        <Typography variant="body1" paragraph>
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
        </Typography>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the US or
            other countries).
          </li>

          <li>
            For the purpose of exploiting, harming or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information or otherwise.
          </li>

          <li>
            To send, knowingly receive, upload, download, use or re-use any
            material which does not comply with these Terms of Use.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail,” “chain letter,”
            “spam,” or any other similar solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate the Company, a Company
            employee, another user or any other person or entity (including,
            without limitation, by using e-mail addresses associated with any of
            the foregoing).
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of the Website, or which, as determined by us, may
            harm the Company or users of the Website, or expose them to
            liability.
          </li>
        </ul>

        <br />
        <Typography variant="body1" paragraph>
          Additionally, you agree not to:
        </Typography>
        <ul>
          <li>
            Use the Website in any manner that could disable, overburden,
            damage, or impair the site or interfere with any other party’s use
            of the Website, including their ability to engage in real time
            activities through the Website.
          </li>
          <li>
            Use any robot, spider or other automatic device, process or means to
            access the Website for any purpose, including monitoring or copying
            any of the material on the Website.
          </li>
          <li>
            Use any manual process to monitor or copy any of the material on the
            Website, or for any other purpose not expressly authorized in these
            Terms of Use, without our prior written consent.
          </li>
          <li>
            Use any device, software or routine that interferes with the proper
            working of the Website.
          </li>
          <li>
            Introduce any viruses, trojan horses, worms, logic bombs or other
            material which is malicious or technologically harmful.
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer or database connected to the
            Website.
          </li>
          <li>
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.
          </li>
          <li>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </ul>
        <Box gutterBottom>&nbsp;</Box>
        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Reliance on Information Posted</u>
        </Typography>

        <Typography variant="body1" paragraph>
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness or usefulness of this information. Any reliance
          you place on such information is strictly at your own risk. We
          disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Website,
          or by anyone who may be informed of any of its contents.
        </Typography>
        <Typography variant="body1" paragraph>
          This Website may include content provided by third parties, including
          materials provided by bloggers and third-party licensors, syndicators,
          aggregators and/or reporting services. All statements and/or opinions
          expressed in these materials, and all articles and responses to
          questions and other content, other than the content provided by the
          Company, are solely the opinions and the responsibility of the person
          or entity providing those materials. These materials do not
          necessarily reflect the opinion of the Company. We are not
          responsible, or liable to you or any third party, for the content or
          accuracy of any materials provided by any third parties.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Changes to the Website</u>
        </Typography>
        <Typography variant="body1" paragraph>
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Information About You and Your Visits to the Website</u>
        </Typography>

        <Typography variant="body1" paragraph>
          All information we collect on this Website is subject to our Privacy
          Policy. By using the Website, you consent to all actions taken by us
          with respect to your information in compliance with the Privacy
          Policy.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Online Purchases and Other Terms and Conditions</u>
        </Typography>

        <Typography variant="body1" paragraph>
          All purchases through our site or other transactions for the sale of
          goods formed through the Website, or as a result of visits made by you
          are governed by our Rental Terms, [
          <Link
            to={"/rental-terms"}
            style={{ color: "blue", borderBottom: "1px solid blue" }}
            target="_blank"
          >
            rental-terms
          </Link>
          ], which are hereby incorporated into these Terms of Use. Additional
          terms and conditions may also apply to specific portions, services or
          features of the Website. All such additional terms and conditions are
          hereby incorporated by this reference into these Terms of Use.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Social Media Features</u>
        </Typography>

        <Typography variant="body1" paragraph>
          This Website may provide certain social media features that enable you
          to:
        </Typography>
        <ul>
          <li>
            Link from your own or certain third-party websites to certain
            content on this Website.
          </li>
          <li>
            Send e-mails or other communications with certain content, or links
            to certain content, on this Website.
          </li>
          <li>
            Cause limited portions of content on this Website to be displayed or
            appear to be displayed on your own or certain third-party websites.
          </li>
        </ul>
        <br />

        <Typography variant="body1" paragraph>
          You may use these features solely as they are provided by us, solely
          with respect to the content they are displayed with, and otherwise in
          accordance with any additional terms and conditions we provide with
          respect to such features. Subject to the foregoing, you must not:
        </Typography>
        <ul>
          <li>Establish a link from any website.</li>
          <li>
            Cause the Website or portions of it to be displayed, or appear to be
            displayed by, for example, framing, deep linking or in-line linking,
            on any other site.
          </li>
          <li>Link to any part of the Website.</li>
          <li>
            Otherwise take any action with respect to the materials on this
            Website that is inconsistent with any other provision of these Terms
            of Use.
          </li>
        </ul>
        <br />
        <Typography variant="body1" paragraph>
          You agree to cooperate with us in causing any unauthorized framing or
          linking immediately to cease. We reserve the right to withdraw linking
          permission without notice. We may disable all or any social media
          features and any links at any time without notice in our discretion.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Links from the Website</u>
        </Typography>

        <Typography variant="body1" paragraph>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms
          and conditions of use for such websites.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Geographic Restrictions</u>
        </Typography>

        <Typography variant="body1" paragraph>
          The owner of the Website is based in the State of Florida in the
          United States. We provide this Website for use only by persons located
          in the United States. We make no claims that the Website or any of its
          content is accessible or appropriate outside of the United States.
          Access to the Website may not be legal by certain persons or in
          certain countries. If you access the Website from outside the United
          States, you do so on your own initiative and are responsible for
          compliance with local laws.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Disclaimer of Warranties</u>
        </Typography>

        <Typography variant="body1" paragraph>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
          COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
        </Typography>
        <Typography variant="body1" paragraph>
          YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT
          LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH
          THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
          RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
          THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY
          SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET
          YOUR NEEDS OR EXPECTATIONS.
        </Typography>
        <Typography variant="body1" paragraph>
          THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED
          TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
          PARTICULAR PURPOSE.
        </Typography>
        <Typography variant="body1" paragraph>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Limitation on Liability</u>
        </Typography>

        <Typography variant="body1" paragraph>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER
          ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
          INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT
          ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
          DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
          OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
          LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
        </Typography>
        <Typography variant="body1" paragraph>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Indemnification</u>
        </Typography>

        <Typography variant="body1" paragraph>
          You agree to defend, indemnify and hold harmless the Company, its
          affiliates, licensors and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses or fees (including reasonable attorneys’ fees) arising out of
          or relating to your violation of these Terms of Use or your use of the
          Website, including, but not limited to, your User Contributions, any
          use of the Website’s content, services and products other than as
          expressly authorized in these Terms of Use, or your use of any
          information obtained from the Website.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Governing Law and Jurisdiction</u>
        </Typography>

        <Typography variant="body1" paragraph>
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of
          Florida without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Florida or any other
          jurisdiction).
        </Typography>
        <Typography variant="body1" paragraph>
          Any legal suit, action or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the federal courts of the United States or the courts of the State of
          Florida, in each case located in the City of Delray Beach and County
          of Palm Beach, although we retain the right to bring any suit, action
          or proceeding against you for breach of these Terms of Use in your
          country of residence or any other relevant country. You waive any and
          all objections to the exercise of jurisdiction over you by such courts
          and to venue in such courts.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Limitation on Time to File Claims</u>
        </Typography>

        <Typography variant="body1" paragraph>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u> Waiver and Severability</u>
        </Typography>

        <Typography variant="body1" paragraph>
          No waiver of by the Company of any term or condition set forth in
          these Terms of Use shall be deemed a further or continuing waiver of
          such term or condition or a waiver of any other term or condition, and
          any failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </Typography>
        <Typography variant="body1" paragraph>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Entire Agreement</u>
        </Typography>

        <Typography variant="body1" paragraph>
          The Terms of Use, our Privacy Policy, and Terms of Sale constitute the
          sole and entire agreement between you and Dizel, Inc. with respect to
          the Website and supersede all prior and contemporaneous
          understandings, agreements, representations and warranties, both
          written and oral, with respect to the Website.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Your Comments and Concerns</u>
        </Typography>

        <Typography variant="body1" paragraph>
          This Website is operated by Dizel, Inc., 3602 Lakeview Boulevard,
          Delray Beach, FL 33445.
        </Typography>
        <Typography variant="body1" paragraph>
          All notices of copyright infringement claims, other feedback,
          comments, request for technical support, and other communications
          relating to the Website should be sent to [
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
            href="mailto:support@godizel.com"
          >
            support@godizel.com
          </a>
          ].
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
