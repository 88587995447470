import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Grid } from "@splidejs/splide-extension-grid";

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  Stack,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import "./CarouselTrending.css";
import { brands } from "../../pages/AddNewItem/data";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import { addtoCart, resetCartMessage } from "../StateSlices/addtocartSlice";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import { getuserInfo } from "../StateSlices/getuserSlice";

const CarouselTrending = ({
  title,
  data,
  cartVal,
  setCartVal,
  setAddedtoCart,
}) => {
  const [chooseModel, setChooseModel] = useState(false);
  const [likes, setLikes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [PId, setPId] = useState(null);
  const [openRentInfoModal, setOpenRentInfoModal] = useState(false);
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState("");
  const { allowedRange, combine, beforeToday } = DateRangePicker;
  const [checkoutRentalDateRange, setCheckoutRentalDateRange] = useState(null);
  const [checkoutRentalPriceType, setCheckoutRentalPriceType] =
    useState("itemDailyPrice");
  const { cstatus, message } = useSelector((state) => state.cart);

  const { userInfo } = useSelector((state) => state.user);

  const AddToCart = (id, productType) => {
    if (productType == "rent") {
      setOpenRentInfoModal(true);
      setPId(id);
      return;
    } else {
      //temporary basis LOCAL STORAGE
      let cartItems = localStorage.getItem("cartItems") || [];
      if (cartItems.length > 0) cartItems = JSON.parse(cartItems);
      cartItems.push({ id, itemType: "buy" });
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      ///////////////////////////////////////////////////////
      let token = localStorage.getItem("inktoken");
      setLoading(true);
      // let cart = [];
      // cart.push(id);
      // sessionStorage.setItem("closetcart", JSON.stringify(cart));
      setCartVal((prev) => [...prev, id]);
      if (userInfo) {
        dispatch(addtoCart({ token, id: { id, itemType: "buy" } }));
      } else {
        setLoading(false);
        setAddedtoCart(true);
        toast.success("Equipment added to cart successfully!", toastOption);
      }

      // setLoading(false);

      // toast.success("Coming Soon", toastOption);
    }
  };
  const handleCheckoutRentalInfo = () => {
    // temporary basis LOCAL STORAGE
    setOpenRentInfoModal(false);
    let cartItems = localStorage.getItem("cartItems") || [];
    if (cartItems.length > 0) cartItems = JSON.parse(cartItems);
    cartItems.push({
      id: PId,
      itemType: "rent",
      checkoutRentalDateRange,
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    ///////////////////////////////////////////////////////
    let token = localStorage.getItem("inktoken");
    setLoading(true);
    // let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    // cart.push(PId);
    // sessionStorage.setItem("closetcart", JSON.stringify(cart));
    // setCartVal(cart);
    setCartVal((prev) => [...prev, PId]);
    const payload = { checkoutRentalDateRange, checkoutRentalPriceType };

    // dispatch(addtoCartForRental({ token, PId, payload }));
    if (userInfo) {
      dispatch(
        addtoCart({
          token,
          id: {
            id: PId,
            itemType: "rent",
            checkoutRentalDateRange,
          },
        })
      );
    } else {
      setLoading(false);
      setAddedtoCart(true);
      toast.success("Equipment added to cart successfully!", toastOption);
    }

    // setLoading(false);
    // toast.success("Equipment added to cart successfully!", toastOption);

    // toast.success("Coming Soon", toastOption);
  };

  const toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };
  const handleLike = (e, id) => {
    e.preventDefault();
    if (likes && likes.length > 0) {
      setLikes((prev) => [...prev, id]);
    } else {
      setLikes([id]);
    }
    toast.success("Equipment added to favorites", toastOption);
  };

  const handleDislike = (e, id) => {
    e.preventDefault();
    setLikes((prev) => prev.filter((item) => item !== id));
    toast.success("Equipment removed from favorites", toastOption);
  };

  useEffect(() => {
    if (message) {
      setLoading(false);
      dispatch(resetCartMessage());
      toast.success("Equipment added to cart successfully!", toastOption);
      let token = localStorage.getItem("inktoken");
      setTimeout(() => {
        if (token) {
          dispatch(getuserInfo({ token: token }));
        }
      }, 1000);
    }
  }, [message]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxWidth: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    zIndex: 1,
  };

  return (
    <>
      {openRentInfoModal && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openRentInfoModal}
          onClose={() => setOpenRentInfoModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openRentInfoModal}>
            <Box sx={style} className="checkout-rental-info-modal">
              <Typography
                id="transition-modal-title"
                variant="h3"
                fontWeight={700}
              >
                Provide Rental Details
              </Typography>
              <Typography id="transition-modal-description" variant="h6">
                Please provide rental information for your selected item for the
                checkout
              </Typography>
              <br />

              <Typography variant="h6" margin={".5rem 0"}>
                Rental Period
              </Typography>
              <DateRangePicker
                defaultOpen
                shouldDisableDate={combine(
                  allowedRange(
                    data[data.findIndex((p) => p._id === PId)].itemLeaseStart,
                    data[data.findIndex((p) => p._id === PId)].itemLeaseEnd
                  ),
                  beforeToday()
                )}
                style={{ width: "100%" }}
                onOk={(newValue) => setCheckoutRentalDateRange(newValue)}
              />
              <br />
              <Button
                type="submit"
                sx={{ margin: "2rem 0" }}
                variant="contained"
                onClick={handleCheckoutRentalInfo}
              >
                Add to Cart
              </Button>
            </Box>
          </Fade>
        </Modal>
      )}
      <Typography
        variant="h1"
        textAlign={"center"}
        margin={"2rem auto"}
        className="title mont-title"
        marginTop={"3rem"}
      >
        {title}
      </Typography>
      <Stack
        margin={"2rem auto"}
        flexWrap={"wrap"}
        direction={"row"}
        justifyContent={"space-between"}
      >
        {/* <div className="cat-car-con"> */}
        {/* <Splide
          aria-label="My Favorite Images"
          options={{
            type: "loop",
            perPage: 3,
            rewind: true,
            grid: {
              // You can define rows/cols instead of dimensions.
              rows: 2,
            },
            rewindSpeed: 1000,
            arrows: false,
            pagination: false,
            drag: true,
            classes: {
              pagination: "splide__pagination pagination_container",
              page: "splide__pagination__page page_container",
            },
            breakpoints: {
              1150: {
                perPage: 3,
              },
              850: {
                perPage: 2,
              },
              570: {
                perPage: 1,
              },
            },
          }}
          // Extensions={{ Grid }}
        > */}
        {data.map((product, index) => (
          // <SplideSlide
          //   key={index}
          //   style={{
          //     minHeight: "320px",
          //     display: "flex",
          //     justifyContent: "center",
          //     margin: "1rem 0",
          //   }}
          // >
          // <Link to={"/product/" + product._id} state={{ id: product._id }}>
          <div style={{ margin: "2rem auto" }}>
            <ProductCard
              shopPageCardWidth={"340px"}
              product={product}
              buttons={
                product.merchantFeed ? ["view details"] : ["view details"]
              }
              cartVal={cartVal}
              setCartVal={setCartVal}
              AddToCart={AddToCart}
              setChooseModel={setChooseModel}
              setCurrentId={setCurrentId}
            />
          </div>
          // </Link>
          //  </SplideSlide>
        ))}
        {/* </Splide> */}
        {/* </div> */}
      </Stack>
    </>
  );
};

export default CarouselTrending;
