import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Typography, Tooltip, Stack } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import "./ProductCard.css";
import { brands } from "../../pages/AddNewItem/data";
import { FaHeart, FaPen, FaRegHeart } from "react-icons/fa6";
import { MdShoppingCart } from "react-icons/md";
import { toast } from "react-toastify";
import { addtoCart } from "../StateSlices/addtocartSlice";
import { useDispatch, useSelector } from "react-redux";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import { getThirdPartyLogo, getThirdPartyName } from "../../utils/utils";
import { handleLike } from "../StateSlices/handleLikeSlice";
import { Info } from "@mui/icons-material";
import dizelBolt from "../../assets/godizel-logo.png";
import swal from "sweetalert";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--white-color)",
    color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 700,
    padding: "8px 20px",
    borderRadius: 30,
    border: "2px solid var(--grey-color)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    // boxShadow: theme.shadows[1],
    // borderRadius: 30,
    color: "var(--grey-color)",
  },
}));

const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--black-color)",
    borderRadius: 0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 50,
    padding: 10,
    backgroundColor: "var(--black-color)",
    border: "2px solid var(--black-color)",
  },
}));

const linkStyle = {
  display: "grid",
  width: "100%",
  margin: "0 .5rem",
  textDecoration: "none",
};

const ProductCard = ({
  cartVal,
  setCartVal,
  product,
  shopPageCardWidth = null,
  shopPageCardHeight = null,
  buttons = [],
  userInfo,
  AddToCart,
  freeze,
  setCurrentId,
  setChooseModel,
  priceByFilter,
  enlistHandler,
  shopLikes = [],
  setShopLikes = null,
  hideLikeIcon = false,
  purchase,
  handleCancelDispatch,
  entireCardClickable = false,
  page = null,
}) => {
  const [openRentInfoModal, setOpenRentInfoModal] = useState(false);
  const [PId, setPId] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [likes, setLikes] = useState(shopLikes);

  const { likeStatus, likeMessage, likeCount } = useSelector(
    (state) => state.like
  );

  const toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    if (likeCount) {
      if (setShopLikes) {
        setShopLikes(likeCount);
      }
      setLikes(likeCount);
      // setLikes(likeCount);
      // toast.info(likeMessage, toastOption);
    }
  }, [likeCount]);
  const handleLikeFunc = (e, id) => {
    e.preventDefault();
    let token = localStorage.getItem("inktoken");
    if (token) {
      dispatch(handleLike({ token, id }));
      // if ( && likes.length > 0) {
      //   setLikes((prev) => [...prev, id]);
      // } else {
      //   setLikes([id]);
      // }
      toast.success("Equipment added to favorites", toastOption);
    } else {
      toast.info("Please log in to manage your preferences", toastOption);
    }
  };

  const handleDislikeFunc = (e, id) => {
    e.preventDefault();
    let token = localStorage.getItem("inktoken");
    if (token) {
      dispatch(handleLike({ token, id }));
      toast.success("Equipment removed from favorites", toastOption);
    } else {
      toast.info("Please log in to manage your preferences", toastOption);
    }

    // setLikes((prev) => prev.filter((item) => item !== id));
  };

  const handleCancel = (id) => {
    console.log("CANCEL", id);
    console.log(purchase);
    console.log(product);
    let rentalStartDate =
      purchase.order.products[
        purchase.order.products.findIndex(
          (prod) => prod.productId == product._id
        )
      ]?.rentalDateRange[0];
    console.log(rentalStartDate);

    if (rentalStartDate) {
      if (product.cancellation) {
        const cancellationDeadline = new Date(rentalStartDate);
        cancellationDeadline.setDate(
          cancellationDeadline.getDate() - product.cancellationDate
        );

        if (
          product.cancellationDate &&
          new Date(cancellationDeadline).getTime() > new Date().getTime()
        ) {
          handleCancelDispatch(purchase?.order._id, product._id);
        } else {
          toast.info(
            "Cancellation Period Passed for this equipment",
            toastOption
          );
          return;
        }
      } else {
        // check if product.checkoutTime is less than 72 hours

        const checkoutTime = new Date(purchase.createdAt).getTime();

        // Calculate the time difference in hours between checkoutTime and the current time
        const currentTime = new Date().getTime();
        const timeDifferenceInMilliseconds = currentTime - checkoutTime;

        const hoursDifference = timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours

        // const cancellationDeadline = new Date(rentalStartDate);
        // cancellationDeadline.setDate(cancellationDeadline.getDate() - 3);

        if (hoursDifference > 72) {
          toast.info(
            "Cancellation not available for this equipment",
            toastOption
          );
          return;
        }
        handleCancelDispatch(purchase?.order?._id, product._id);
      }
    } else {
      toast.info("Cancellation not available for this equipment", toastOption);
      return;
    }
  };

  return (
    <Link
      to={page ? `mailto:${page.sellerEmail}` : "/product/" + product._id}
      target={page ? "_blank" : ""}
      onClick={page ? (e)=>{
        e.preventDefault();
        window.open(`mailto:${page.sellerEmail}`, '_blank')
      } : null}
      state={{ id: product._id }}
      style={{ position: "relative", textDecoration: "none" }}
    >
      <div
        className="shop-page-card"
        style={{ width: shopPageCardWidth ? shopPageCardWidth : "340px" }}
        // style={{ margin: removeMargins ? 0 : "1rem" }}
      >
        <div
          className="img-con"
          style={{ height: shopPageCardHeight ? shopPageCardHeight : "220px" }}
        >
          {!product.merchantFeed ? (
            <div className="dizel-bolt">
              <DarkToolTip
                arrow
                className="gd-tooltip"
                placement="right"
                title={"Exclusive Dizel Equipment"}
              >
                <img
                  alt="dizel-bolt"
                  src={dizelBolt}
                  width={"100%"}
                  style={{ borderRadius: "50%" }}
                />
              </DarkToolTip>
            </div>
          ) : (
            <div className="dizel-bolt third-party-logo">
              <DarkToolTip
                arrow
                className="gd-tooltip"
                placement="right"
                title={`${getThirdPartyName(product.link)} Equipment`}
              >
                <img
                  alt={getThirdPartyName(product.link)}
                  src={getThirdPartyLogo(product.link)}
                  width={"100%"}
                  style={{ borderRadius: "50%" }}
                />
              </DarkToolTip>
            </div>
          )}
          <img src={product.itemImages[0]} alt={product.itemName} />
          {(product.itemToHold || product.draft || product.delisted) &&
          buttons[0] === "Edit Listing" ? (
            <div className="hold_draft">
              <Chip
                className="chip"
                sx={{
                  // padding: "14px 8px",
                  // paddingLeft: "8px",
                  // paddingRight: "8px",
                  height: 40,
                  minWidth: "40px",
                  borderRadius: 40,
                  marginLeft: product.itemToSell === true ? "1rem" : "0",
                }}
                label={
                  <DarkToolTip
                    arrow
                    className="gd-tooltip"
                    placement="left"
                    title={
                      product.itemToHold
                        ? "This equipment is currently on-hold and not visible to others."
                        : product.draft
                        ? "This equipment has incomplete information, and is not shown on marketplace."
                        : "This equipment is delisted and not shown on marketplace."
                    }
                  >
                    <Typography
                      display={"flex"}
                      alignItems={"center"}
                      variant="h6"
                      fontWeight={700}
                      lineHeight={"18px"}
                      fontSize={"14px"}
                    >
                      <Info sx={{ marginRight: ".2rem" }} fontSize={"14px"} />
                      {product.delisted
                        ? "Delisted"
                        : product.itemToHold
                        ? "Hold"
                        : "Draft"}
                    </Typography>
                  </DarkToolTip>
                }
              />
            </div>
          ) : (
            ""
          )}

          {!(product.itemToHold || product.draft || product.delisted) &&
            !hideLikeIcon && (
              <div className="heart">
                {likes && likes.indexOf(product._id) !== -1 ? (
                  <FaHeart
                    size={"1.2em"}
                    style={{ cursor: "pointer" }}
                    color="var(--secondary-color)"
                    onClick={(e) => handleDislikeFunc(e, product._id)}
                  />
                ) : (
                  <FaRegHeart
                    style={{ cursor: "pointer" }}
                    color="var(--grey-color)"
                    size={"1.2em"}
                    onClick={(e) => handleLikeFunc(e, product._id)}
                  />
                )}
              </div>
            )}
        </div>
        <div className="text-content">
          <div className="chip-con cart-icon">
            {userInfo &&
            userInfo.userProducts.findIndex(
              (prod) => prod._id === product._id
            ) > -1 ? (
              <Link
                to={`/editproduct/${product.itemBrand?`${encodeURIComponent(
                  brands.find((brand) => brand._id === product.itemBrand).label
                )}-`:''}${encodeURIComponent(product.itemName.replace(/\//g, '-'))}`}
                state={{ id: product._id }}
                // style={linkStyle}
              >
                <Chip
                  className="chip-info"
                  sx={{
                    marginRight: "1rem",
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  label={<FaPen size={"1.5em"} />}
                />
              </Link>
            ) : (
              <Chip
                className="chip-info"
                sx={{
                  marginRight: "1rem",
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
                label={<MdShoppingCart size={"1.5em"} />}
              />
            )}
          </div>
          {/* <div className="chip-con">
            {product.itemForRent === true &&
              product.merchantFeed === false &&
              !priceByFilter && (
                <>
                  {product.itemToSell === true ? (
                    <LightTooltip
                      sx={{ background: "var(--white-color)" }}
                      title="Available for rent"
                      arrow
                      placement="top"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 500 }}
                    >
                      <Chip
                        className="chip-info"
                        sx={{
                          marginRight: "1rem",
                          paddingLeft: 6,
                          paddingRight: 6,
                        }}
                        label={<FaCircleInfo size={"1.5em"} />}
                      />
                    </LightTooltip>
                  ) : (
                    <Chip
                      className="chip"
                      sx={{
                        marginLeft: product.itemToSell === true ? "1rem" : "0",
                      }}
                      label={
                        product.itemDailyPrice && product.itemDailyPrice !== 0
                          ? "Rent for $" +
                            product.itemDailyPrice.toLocaleString("en-US") +
                            " per day"
                          : product.itemWeeklyPrice &&
                            product.itemWeeklyPrice !== 0
                          ? "Rent for $" +
                            product.itemWeeklyPrice.toLocaleString("en-US") +
                            " per week"
                          : "Rent for $" + product.itemMonthlyPrice &&
                            product.itemMonthlyPrice.toLocaleString("en-US") +
                              " per month"
                      }
                    />
                  )}
                </>
              )}

            {product.itemForRent === true &&
              product.merchantFeed === false &&
              priceByFilter && (
                <>
                  {product.itemToSell === true ? (
                    <LightTooltip
                      className="tooltip-info-rent"
                      title="Available for rent"
                      arrow
                      placement="top"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 500 }}
                    >
                      <Chip
                        className="chip-info"
                        sx={{
                          marginRight: "1rem",
                        }}
                        label={<FaCircleInfo size={"1.5em"} />}
                      />
                    </LightTooltip>
                  ) : (
                    <Chip
                      className="chip"
                      sx={{
                        marginLeft: product.itemToSell === true ? "1rem" : "0",
                      }}
                      label={
                        !priceByFilter || priceByFilter === "itemDailyPrice"
                          ? "Rent for $" +
                            (product.itemDailyPrice
                              ? product.itemDailyPrice.toLocaleString("en-US")
                              : product.itemWeeklyPrice
                              ? (product.itemWeeklyPrice / 7)
                                  .toFixed(2)
                                  .toLocaleString("en-US")
                              : (product.itemMonthlyPrice / 30)
                                  .toFixed(2)
                                  .toLocaleString("en-US")) +
                            " per day"
                          : !priceByFilter ||
                            priceByFilter === "itemWeeklyPrice"
                          ? "Rent for $" +
                            (product.itemWeeklyPrice
                              ? product.itemWeeklyPrice.toLocaleString("en-US")
                              : product.itemDailyPrice
                              ? (product.itemDailyPrice * 7)
                                  .toFixed(2)
                                  .toLocaleString("en-US")
                              : (product.itemMonthlyPrice / 30)
                                  .toFixed(2)
                                  .toLocaleString("en-US")) +
                            " per week"
                          : !priceByFilter ||
                            priceByFilter === "itemMonthlyPrice"
                          ? "Rent for $" +
                            (product.itemMonthlyPrice &&
                            product.itemMonthlyPrice
                              ? product.itemMonthlyPrice.toLocaleString("en-US")
                              : product.itemDailyPrice
                              ? (product.itemDailyPrice * 30)
                                  .toFixed(2)
                                  .toLocaleString("en-US")
                              : (product.itemWeeklyPrice * 4)
                                  .toFixed(2)
                                  .toLocaleString("en-US")) +
                            " per month"
                          : ""
                      }
                    />
                  )}
                </>
              )}

            {product.itemToSell === true && product.merchantFeed === false && (
              <Chip
                className="chip"
                label={
                  product.itemAmount &&
                  product.itemAmount !== 0 &&
                  "Buy for $" + product.itemAmount.toLocaleString("en-US")
                }
              />
            )}

           
            {product.merchantFeed &&
              (product.itemForRent === true ? (
                product.itemToSell ? (
                  <>
                    {product.itemToSell === true ? (
                      <LightTooltip
                        className="tooltip-info-rent"
                        title="Available for rent"
                        arrow
                        placement="top"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 500 }}
                      >
                        <Chip
                          className="chip-info"
                          sx={{
                            marginRight: "1rem",
                          }}
                          label={<FaCircleInfo size={"1.5em"} />}
                        />
                      </LightTooltip>
                    ) : (
                      <Chip
                        className="chip"
                        sx={{
                          marginLeft:
                            product.itemToSell === true ? "1rem" : "0",
                        }}
                        label={
                          !priceByFilter || priceByFilter === "itemDailyPrice"
                            ? "Rent for $" +
                              (product.itemDailyPrice
                                ? product.itemDailyPrice.toLocaleString("en-US")
                                : product.itemWeeklyPrice
                                ? (product.itemWeeklyPrice / 7)
                                    .toFixed(2)
                                    .toLocaleString("en-US")
                                : (product.itemMonthlyPrice / 30)
                                    .toFixed(2)
                                    .toLocaleString("en-US")) +
                              " per day"
                            : !priceByFilter ||
                              priceByFilter === "itemWeeklyPrice"
                            ? "Rent for $" +
                              (product.itemWeeklyPrice
                                ? product.itemWeeklyPrice.toLocaleString(
                                    "en-US"
                                  )
                                : product.itemDailyPrice
                                ? (product.itemDailyPrice * 7)
                                    .toFixed(2)
                                    .toLocaleString("en-US")
                                : (product.itemMonthlyPrice / 30)
                                    .toFixed(2)
                                    .toLocaleString("en-US")) +
                              " per week"
                            : !priceByFilter ||
                              priceByFilter === "itemMonthlyPrice"
                            ? "Rent for $" +
                              (product.itemMonthlyPrice &&
                              product.itemMonthlyPrice
                                ? product.itemMonthlyPrice.toLocaleString(
                                    "en-US"
                                  )
                                : product.itemDailyPrice
                                ? (product.itemDailyPrice * 30)
                                    .toFixed(2)
                                    .toLocaleString("en-US")
                                : (product.itemWeeklyPrice * 4)
                                    .toFixed(2)
                                    .toLocaleString("en-US")) +
                              " per month"
                            : ""
                        }
                      />
                    )}
                  </>
                ) : (
                  <Chip
                    className="chip"
                    sx={{
                      marginLeft: product.itemToSell === true ? "1rem" : "0",
                    }}
                    label={
                      product.itemDailyPrice && product.itemDailyPrice !== 0
                        ? "Rent from $" +
                          product.itemDailyPrice.toLocaleString("en-US") +
                          "+"
                        : product.itemWeeklyPrice &&
                          product.itemWeeklyPrice !== 0
                        ? "Rent from $" +
                          product.itemWeeklyPrice.toLocaleString("en-US") +
                          "+"
                        : "Rent from $" + product.itemMonthlyPrice &&
                          product.itemMonthlyPrice.toLocaleString("en-US") + "+"
                    }
                  />
                )
              ) : (
                <Chip
                  className="chip"
                  label={
                    product.itemAmount &&
                    product.itemAmount !== 0 &&
                    "Buy from $" +
                      product.itemAmount.toLocaleString("en-US") +
                      " +"
                  }
                />
              ))}
          </div> */}
          <div className="chip-con"></div>

          <Typography
            variant="h5"
            className="title"
            // my={0.3}
            color={"var(--white-color)"}
            mt={1}
            padding={"0 .5rem"}
            fontWeight={700}
          >
            {product.itemName}
          </Typography>
          <Box
            component={"div"}
            display={"flex"}
            padding={"0 .5rem"}
            /*my={0.5}*/ alignItems={"center"}
          >
            <Typography variant="body2" color={"var(--grey-color)"}>
              {product.itemBrand &&
                brands.filter((brnd) => brnd._id === product.itemBrand)[0]
                  .label}
            </Typography>
            {product.itemYearManufacture && (
              <>
                <span className="dot"></span>
                <Typography variant="body2" color={"var(--grey-color)"}>
                  {product.itemYearManufacture}
                </Typography>
              </>
            )}
            {product.itemHoursUsed > 0 && (
              <>
                <span className="dot"></span>
                <Typography variant="body2" color={"var(--grey-color)"}>
                  Used {product.itemHoursUsed} hours
                </Typography>
              </>
            )}
          </Box>

          <Typography
            padding={"0 .5rem"}
            variant="body2"
            className="location"
            // my={0.5}
            color={"var(--grey-color)"}
          >
            <i>
              {userInfo &&
              userInfo.userProducts.findIndex(
                (prod) => prod._id === product._id
              ) == -1 ? (
               <>
                {
                  product.itemCity ? (
                    product.itemCity + ", " + product.itemState
                  ):<span>&nbsp;</span>
                }
               </>
                
              ) : // product.itemLocation ""
              product.itemCity ? (
                product.itemCity + ", " + product.itemState
              ) : (
                <span>&nbsp;</span>
              )}
            </i>
          </Typography>

          <Typography
            padding={"0 .5rem"}
            variant="body2"
            className="location"
            // my={0.5}
            color={"var(--grey-color)"}
          >
            <i>
              {!product.itemBrand && !product.itemYearManufacture && !product.itemHoursUsed && (
                <span>&nbsp;</span>
              )}
            </i>
          </Typography>

          <Box
            component={"div"}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: 1,
              mb: 1,
            }}
          >
            {buttons && buttons.length > 0 ? (
              <>
                {buttons.map((btn, ind) => {
                  if (btn.toLowerCase() === "edit listing") {
                    return product.delisted ? (
                      <Button
                        variant="outlined"
                        fullWidth={true}
                        onClick={() => enlistHandler(product._id)}
                        // fullWidth
                        sx={{
                          margin: "0 .5rem",
                          border: "1px solid var(--white-color)",
                          color: "var(--white-color)",
                          fontSize: "14px",
                          "&:hover": {
                            border: "1px solid var(--grey-color)",
                            color: "var(--grey-color)",
                          },
                          textTransform: "none",
                        }}
                      >
                        {"Enlist this equipment"}
                      </Button>
                    ) : (
                      <Link
                        to={`/editproduct/${product.itemBrand?`${encodeURIComponent(
                          brands.find((brand) => brand._id === product.itemBrand).label
                        )}-`:''}${encodeURIComponent(product.itemName.replace(/\//g, '-'))}`}
                        state={{ id: product._id }}
                        style={linkStyle}
                      >
                        <Button
                          variant="outlined"
                          // fullWidth
                          sx={{
                            border: "1px solid var(--white-color)",
                            color: "var(--white-color)",
                            fontSize: "14px",
                            "&:hover": {
                              border: "1px solid var(--grey-color)",
                              color: "var(--grey-color)",
                            },
                          }}
                        >
                          {product.draft
                            ? "Complete Draft"
                            : product.itemToHold
                            ? "Edit Availability"
                            : "Edit Details or Availability"}
                        </Button>
                      </Link>
                    );
                  } else if (btn.toLowerCase() === "view details") {
                    return (
                      <Link
                        to={"/product/" + product._id}
                        state={{ id: product._id }}
                        style={linkStyle}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            border: "1px solid var(--white-color)",
                            color: "var(--white-color)",
                            fontSize: "14px",
                            // width: buttons.length === 1 ? "100%" : "150px",
                            "&:hover": {
                              border: "1px solid var(--grey-color)",
                              color: "var(--grey-color)",
                            },
                          }}
                        >
                          View Details
                        </Button>
                      </Link>
                    );
                  } else if (btn.toLowerCase() === "cancel order") {
                    return (
                      <Button
                        variant="outlined"
                        sx={{
                          zIndex: 9999,
                          position: "relative",
                          border: "1px solid var(--white-color)",
                          color: "var(--white-color)",
                          fontSize: "14px",
                          // width: buttons.length === 1 ? "100%" : "150px",
                          "&:hover": {
                            border: "1px solid var(--grey-color)",
                            color: "var(--grey-color)",
                          },
                        }}
                        style={linkStyle}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (
                            purchase?.order?.products[
                              purchase?.order?.products.findIndex(
                                (prod) => prod.productId == product._id
                              )
                            ]?.status == undefined ||
                            purchase?.order?.products[
                              purchase?.order?.products.findIndex(
                                (prod) => prod.productId == product._id
                              )
                            ]?.status
                          ) {
                            handleCancel(product._id);
                          }
                        }}
                      >
                        {
                          // Check if the status property is undefined or truthy (true/false)
                          purchase?.order?.products[
                            purchase?.order?.products.findIndex(
                              (prod) => prod.productId == product._id
                            )
                          ]?.status == undefined ||
                          purchase?.order?.products[
                            purchase?.order?.products.findIndex(
                              (prod) => prod.productId == product._id
                            )
                          ]?.status
                            ? // If the status is undefined or truthy (true), display "Cancel Order"
                              "Cancel Order"
                            : // If the status is false (and defined), display "Cancelled Order"
                              "Order Cancelled"
                        }
                      </Button>
                    );
                  } else if (btn.toLowerCase() === "add to cart") {
                    return (
                      <>
                        {userInfo &&
                        userInfo.userProducts.findIndex(
                          (prod) => prod._id === product._id
                        ) > -1 ? (
                          <Link
                            to={`/editproduct/${encodeURIComponent(
                              brands.find(
                                (brand) => brand._id === product.itemBrand
                              ).label
                            )}-${encodeURIComponent(product.itemName.replace(/\//g, '-'))}`}
                            state={{ id: product._id }}
                            style={linkStyle}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                background: "var(--white-color)",
                                border: "1px solid var(--black-color)",
                                color: "var(--black-color)",
                                fontSize: "14px",
                                // width: "150px",
                                "&:hover": {
                                  background: "var(--grey-color)",
                                  border: "1px solid var(--black-color)",
                                  color: "var(--black-color)",
                                },
                              }}
                            >
                              Edit Item
                            </Button>
                          </Link>
                        ) : (
                          <>
                            {userInfo &&
                            userInfo.userProductsInfo.cartId.findIndex(
                              (p) => p.id == product._id
                            ) > -1 ? (
                              <>
                                <Link
                                  to={`/checkout`}
                                  state={{ id: product._id }}
                                  style={linkStyle}
                                >
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      background: "var(--white-color)",
                                      border: "1px solid var(--black-color)",
                                      color: "var(--black-color)",
                                      fontSize: "14px",
                                      // width: "150px",
                                      "&:hover": {
                                        background: "var(--grey-color)",
                                        border: "1px solid var(--black-color)",
                                        color: "var(--black-color)",
                                      },
                                    }}
                                  >
                                    Go to Cart
                                  </Button>
                                </Link>
                              </>
                            ) : (
                              <Button
                                variant="outlined"
                                sx={{
                                  background: "var(--white-color)",
                                  border: "1px solid var(--black-color)",
                                  color: "var(--black-color)",
                                  fontSize: "14px",
                                  display: "grid",
                                  width: "100%",
                                  margin: "0 .5rem",
                                  // width: "150px",
                                  textTransform: "none",
                                  "&:hover": {
                                    background: "var(--grey-color)",
                                    border: "1px solid var(--black-color)",
                                    color: "var(--black-color)",
                                  },
                                }}
                                onClick={() => {
                                  if (product.itemToHold) {
                                    toast.info(
                                      "This Equipment is On-Hold and not available currently.",
                                      toastOption
                                    );
                                    return;
                                  } else {
                                    product.itemForRent && product.itemToSell
                                      ? setChooseModel(true)
                                      : AddToCart(
                                          product._id,
                                          product.itemForRent ? "rent" : "sell"
                                        );
                                    if (
                                      product.itemForRent &&
                                      product.itemToSell
                                    ) {
                                      setCurrentId(product._id);
                                    } else {
                                      setCurrentId("");
                                    }
                                  }
                                }}
                              >
                                Add to Cart
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    );
                  } else if (btn.toLowerCase() === "add to cart merchant") {
                    return (
                      <>
                        {userInfo &&
                        userInfo.userProducts.findIndex(
                          (prod) => prod._id === product._id
                        ) > -1 ? (
                          <Link
                            to={`/editproduct/${encodeURIComponent(
                              brands.find(
                                (brand) => brand._id === product.itemBrand
                              ).label
                            )}-${encodeURIComponent(product.itemName.replace(/\//g, '-'))}`}
                            state={{ id: product._id }}
                            style={linkStyle}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                background: "var(--white-color)",
                                border: "1px solid var(--black-color)",
                                color: "var(--black-color)",
                                fontSize: "14px",
                                // width: "150px",
                                "&:hover": {
                                  background: "var(--grey-color)",
                                  border: "1px solid var(--black-color)",
                                  color: "var(--black-color)",
                                },
                              }}
                            >
                              Edit Item
                            </Button>
                          </Link>
                        ) : (
                          <>
                            <LightTooltip
                              className="tooltip-info-rent"
                              title={`This is an equipment from ${getThirdPartyName(
                                product.link
                              )} and the link will take you to their product page`}
                              arrow
                              placement="top-end"
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                            >
                              <a
                                href={product.link}
                                target="_blank"
                                style={linkStyle}
                                rel="noreferrer"
                              >
                                <Button
                                  variant="outlined"
                                  sx={{
                                    background: "var(--white-color)",
                                    border: "1px solid var(--black-color)",
                                    color: "var(--black-color)",
                                    fontSize: "14px",
                                    // width: "150px",
                                    textTransform: "none",
                                    "&:hover": {
                                      background: "var(--grey-color)",
                                      border: "1px solid var(--black-color)",
                                      color: "var(--black-color)",
                                    },
                                  }}
                                >
                                  Add to Cart
                                </Button>
                              </a>
                            </LightTooltip>
                          </>
                        )}
                      </>
                    );
                  }
                  return <></>;
                })}
              </>
            ) : (
              <>
                {/* {
                <Stack
                  gap={1}
                  direction={"row"}
                  flexGrow={1}
                  padding={"0 .5rem"}
                >
                </Stack>
              } */}
                {product.itemToSell && product.itemForRent && !page ? (
                  <Stack
                    gap={1}
                    direction={"row"}
                    flexGrow={1}
                    padding={"0 .5rem"}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        // letterSpacing: 1,
                        width: "50%",
                        border: "1px solid var(--white-color)",
                        color: "var(--white-color)",
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: 700,
                        "&:hover": {
                          border: "1px solid var(--grey-color)",
                          color: "var(--grey-color)",
                        },
                      }}
                    >
                      Buy for ${product.itemAmount.toLocaleString("en-US")}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        // letterSpacing: 1,
                        width: "50%",
                        background: "var(--white-color)",
                        border: "1px solid var(--black-color)",
                        color: "var(--black-color)",
                        fontSize: "14px",
                        fontWeight: 700,
                        textTransform: "none",
                        "&:hover": {
                          background: "var(--grey-color)",
                          border: "1px solid var(--black-color)",
                          color: "var(--black-color)",
                        },
                      }}
                    >
                      {product.itemDailyPrice && product.itemDailyPrice !== 0
                        ? "Rent from $" +
                          product.itemDailyPrice.toLocaleString("en-US") +
                          "+"
                        : product.itemWeeklyPrice &&
                          product.itemWeeklyPrice !== 0
                        ? "Rent from $" +
                          product.itemWeeklyPrice.toLocaleString("en-US") +
                          "+"
                        : "Rent from $" + product.itemMonthlyPrice &&
                          product.itemMonthlyPrice.toLocaleString("en-US") +
                            "+"}
                    </Button>
                  </Stack>
                ) : product.itemToSell && !page ? (
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      // letterSpacing: 1.05,
                      background: "var(--white-color)",
                      border: "1px solid var(--black-color)",
                      color: "var(--black-color)",
                      fontSize: "14px",
                      fontWeight: 700,
                      textTransform: "none",
                      "&:hover": {
                        background: "var(--grey-color)",
                        border: "1px solid var(--black-color)",
                        color: "var(--black-color)",
                      },
                    }}
                  >
                    Buy for ${product.itemAmount.toLocaleString("en-US")}
                  </Button>
                ) : !page ? (
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      // letterSpacing: 1.05,
                      background: "var(--white-color)",
                      border: "1px solid var(--black-color)",
                      color: "var(--black-color)",
                      fontSize: "14px",
                      fontWeight: 700,
                      textTransform: "none",
                      "&:hover": {
                        background: "var(--grey-color)",
                        border: "1px solid var(--black-color)",
                        color: "var(--black-color)",
                      },
                    }}
                  >
                    {product.itemDailyPrice && product.itemDailyPrice !== 0
                      ? "Rent from $" +
                        product.itemDailyPrice.toLocaleString("en-US") +
                        "+"
                      : product.itemWeeklyPrice && product.itemWeeklyPrice !== 0
                      ? "Rent from $" +
                        product.itemWeeklyPrice.toLocaleString("en-US") +
                        "+"
                      : "Rent from $" + product.itemMonthlyPrice &&
                        product.itemMonthlyPrice.toLocaleString("en-US") + "+"}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      // letterSpacing: 1.05,
                      background: "var(--white-color)",
                      border: "1px solid var(--black-color)",
                      color: "var(--black-color)",
                      fontSize: "14px",
                      fontWeight: 700,
                      textTransform: "none",
                      "&:hover": {
                        background: "var(--grey-color)",
                        border: "1px solid var(--black-color)",
                        color: "var(--black-color)",
                      },
                      zIndex: 99999,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation(); // Prevent the Link from triggering
                      // mailto page.sellerEmail
                      // window.location.href = `mailto:${page.sellerEmail}`;
                      window.open(
                        `mailto:${page.sellerEmail}`,
                        "_blank"
                      )
                    }}
                  >
                    Contact Seller
                  </Button>
                )}
              </>
            )}
          </Box>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
