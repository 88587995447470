import { otherLogos } from "../pages/AddNewItem/data";
import dizelLogo from "../assets/godizel-logo.png";
import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const getThirdPartyLogo = (link) => {
  console.log("LINK ", link);
  const domainLogoMapping = {
    unitedrentals: otherLogos["unitedRentals"],
    bigrentz: otherLogos["bigRentz"],
    sunbeltrentals: otherLogos["sunBeltRentals"],
    rentalmax: otherLogos["rentalMax"],
    compactpowerrents: otherLogos["comparePowerRents"],
    hercrentals: otherLogos["hercRentals"],
    homedepot: otherLogos["comparePowerRents"],
    equipmentshare: otherLogos["equipmentshare"],
  };
  const parsedUrl = new URL(link);
  const hostname = parsedUrl.hostname;
  const domainParts = hostname.split(".");
  let domainName = "";
  if (domainParts.length > 2) {
    domainName = domainParts.slice(1, -1).join(".");
  } else {
    domainName = domainParts.join(".");
  }
  console.log("domain name", domainName);
  return domainLogoMapping[domainName] || dizelLogo;
};

export const getThirdPartyName = (link) => {
  const nameMappings = {
    unitedrentals: "United Rentals",
    bigrentz: "Big Rentz",
    sunbeltrentals: "Sunbelt Rentals",
    rentalmax: "Rental Max",
    compactpowerrents: "Home Depot",
    homedepot: "Home Depot",
    hercrentals: "Herc Rentals",
    equipmentshare: "Equipment Share"
  };
  const parsedUrl = new URL(link);
  const hostname = parsedUrl.hostname;
  const domainParts = hostname.split(".");
  if (domainParts.length > 2) {
    return nameMappings[domainParts.slice(1, -1).join(".")] || "Dizel";
  } else {
    return nameMappings[domainParts.join(".")] || "Dizel";
  }
};

const isDateWithinRange = (date, rangeStart, rangeEnd) => {
  return date >= rangeStart && date <= rangeEnd;
};

const beforeTodayy = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

export const shouldDisableDatee = (date, leaseFields, itemBuyRange) => {
  const isWithinLeaseField =
    leaseFields &&
    leaseFields.some(({ from, to }) => {
      const rangeStart = new Date(from);
      const rangeEnd = new Date(to);
      return isDateWithinRange(date, rangeStart, rangeEnd);
    });

  const isWithinBuyRange =
    itemBuyRange &&
    itemBuyRange.some(({ from, to }) => {
      const rangeStart = new Date(from);
      const rangeEnd = new Date(to);
      // const actualRangeEnd = rangeEnd.setDate(rangeEnd.getDate() + 1);
      return isDateWithinRange(date, rangeStart, rangeEnd);
    });

  const isBeforeToday = beforeTodayy(date);

  return isWithinLeaseField || isBeforeToday || isWithinBuyRange;
};

export const toastOption = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const sortDataProducts = (products, sort, type) => {
  if (products) {
    let sortedProducts = products;
    if (products.length > 1) {
      if (sort && sort === "recent") {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 5);
        sortedProducts = sortedProducts.filter(
          (p) => new Date(p.createdAt) >= twoDaysAgo
        );
      } else {
        sortedProducts = products.slice().sort((a, b) => {
          switch (sort) {
            case "newest":
              return new Date(b.createdAt) - new Date(a.createdAt);
            case "oldest":
              return new Date(a.createdAt) - new Date(b.createdAt);
            case "recent":
              const now = new Date();
              const twoDaysAgo = now.setDate(now.getDate() - 2);
              const isRecentA = new Date(a.createdAt) >= new Date(twoDaysAgo);
              const isRecentB = new Date(b.createdAt) >= new Date(twoDaysAgo);
              if (isRecentA && !isRecentB) return -1;
              if (!isRecentA && isRecentB) return 1;
              return 0;
            case "price":
              if (type === "rent") {
                return a?.itemDailyPrice - b?.itemDailyPrice || 0;
              } else if (type === "buy") {
                return a.itemAmount - b.itemAmount;
              } else if (type === "both") {
                if (a.itemToSell && b.itemToSell) {
                  return a.itemAmount - b.itemAmount;
                } else if (a.itemForRent && b.itemForRent) {
                  return a?.itemDailyPrice - b?.itemDailyPrice || 0;
                } else if (a.itemForRent && b.itemToSell) {
                  return a?.itemDailyPrice - b.itemAmount;
                } else if (a.itemToSell && b.itemForRent) {
                  return a.itemAmount - b?.itemDailyPrice;
                } else {
                  return 0;
                }
              }
              return 0;
            case "price-desc":
              if (type === "rent") {
                return b?.itemDailyPrice - a?.itemDailyPrice || 0;
              } else if (type === "buy") {
                return b.itemAmount - a.itemAmount;
              } else if (type === "both") {
                if (b.itemToSell && a.itemToSell) {
                  return b.itemAmount - a.itemAmount;
                } else if (b.itemForRent && a.itemForRent) {
                  return b?.itemDailyPrice - a?.itemDailyPrice || 0;
                } else if (b.itemForRent && a.itemToSell) {
                  return b?.itemDailyPrice - a.itemAmount;
                } else if (b.itemToSell && a.itemForRent) {
                  return b.itemAmount - a?.itemDailyPrice;
                } else {
                  return 0;
                }
              }
              return 0;
            default:
              return 0; // No sorting
          }
        });
      }
    }
    return sortedProducts;
  }
};

export const sortDataPurchaseOrder = (products, sort, type) => {
  if (products) {
    let sortedProducts = products;
    if (products.length > 1) {
      if (sort && sort === "recent") {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 5);
        sortedProducts = sortedProducts.filter(
          (p) => new Date(p.createdAt) >= twoDaysAgo
        );
      } else {
        sortedProducts = products.slice().sort((a, b) => {
          switch (sort) {
            case "newest":
              return new Date(b.createdAt) - new Date(a.createdAt);
            case "oldest":
              return new Date(a.createdAt) - new Date(b.createdAt);
            case "recent":
              const now = new Date();
              const twoDaysAgo = now.setDate(now.getDate() - 2);
              const isRecentA = new Date(a.createdAt) >= new Date(twoDaysAgo);
              const isRecentB = new Date(b.createdAt) >= new Date(twoDaysAgo);
              if (isRecentA && !isRecentB) return -1;
              if (!isRecentA && isRecentB) return 1;
              return 0;
            case "price":
              return a.pricePaid - b.pricePaid;
            case "price-desc":
              return b.pricePaid - a.pricePaid;
            default:
              return 0; // No sorting
          }
        });
      }
    }
    return sortedProducts;
  }
};

export const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--black-color)",
    borderRadius: 0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 30,
    padding: 10,
    backgroundColor: "var(--black-color)",
    border: "2px solid var(--black-color)",
    textAlign: "center",
  },
}));
