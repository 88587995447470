import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { brands, fldMappings } from "../../pages/AddNewItem/data";
import { FaBagShopping, FaPen } from "react-icons/fa6";
import { toast } from "react-toastify";
import { toastOption } from "../../utils/utils";
import { ExpandCircleDown } from "@mui/icons-material";
import "./Product.css";

const chipStyle = {
  padding: "1.25rem 1.5rem",
  color: "var(--white-color)",
  background: "var(--black-color)",
  cursor: "pointer",
  transition: ".3s ease",
  borderRadius: "30px",
  margin: ".5rem auto",
  minWidth: "320px",
  "&:hover": {
    background: "var(--dark-grey-color)",
    transform: "scale(1.05)",
    transition: ".3s ease",
  },
};

const Description = ({
  product,
  userInfo,
  AddToCart,
  cartVal,
  getThirdPartyName,
}) => {
  return (
    <>
      <section className="description">
        <Typography variant="h3" my={"1rem"} className="mont-title">
          {product.itemName}
        </Typography>
        <Typography variant="p" mb={"0.5rem"}>
          {product.itemDescription}
        </Typography>

        {!product.merchantFeed && (product.itemCity || product.itemState) && (
          <>
            <Typography variant="h5" my={1}>
              <i>
                Location:&nbsp;
                {[
                  // product.itemLocation,
                  product.itemCity,
                  product.itemState,
                  // product.itemZipCode,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </i>
            </Typography>
          </>
        )}
        {product.itemForRent && (
          <i>
            <Typography variant="p" mb={"0.5rem"}>
              Price:{" "}
              {/* {product.itemToSell &&
            "$" + product.itemAmount.toLocaleString("en-us")} */}
              {product.itemForRent &&
                "$" + product.itemDailyPrice &&
                product.itemDailyPrice > 0 &&
                "$" + product.itemDailyPrice.toLocaleString("en-us") + " Daily"}
              {product.itemForRent &&
                product.itemWeeklyPrice &&
                product.itemWeeklyPrice > 0 &&
                ", $" +
                  product.itemWeeklyPrice.toLocaleString("en-us") +
                  " Weekly"}
              {product.itemMonthlyPrice &&
                product.itemMonthlyPrice > 0 &&
                ", $" +
                  product.itemMonthlyPrice.toLocaleString("en-us") +
                  " Monthly"}
            </Typography>
          </i>
        )}

        <Box
          component={"div"}
          mt={".5rem"}
          mb={"1.5rem"}
          className="prod-details-btn-con"
        >
          {product.merchantFeed && product.link ? (
            <>
              <Chip
                sx={{
                  ...chipStyle,
                  background: "var(--secondary-color)",
                  ":hover": {
                    background: "var(--grey-color)",
                  },
                }}
                style={{ marginRight: "1rem", width: "100%" }}
                onClick={() => {
                  window.open(product.link, "_blank");
                }}
                label={
                  product.merchantFeed
                    ? "View Equipment from " + getThirdPartyName(product.link)
                    : `View Equipment`
                }
              />
              {/* {product.itemForRent && (
                <>
                  {product.itemDailyPrice && product.itemDailyPrice !== 0 && (
                    <Chip
                      sx={chipStyle}
                      style={{ marginRight: "1rem" }}
                      onClick={() => {
                        window.open(product.link, "_blank");
                      }}
                      label={
                        "Rent for $" +
                        product.itemDailyPrice.toLocaleString("en-US") +
                        " per day"
                      }
                    />
                  )}
                  {product.itemWeeklyPrice && product.itemWeeklyPrice !== 0 && (
                    <Chip
                      style={{ marginRight: "1rem" }}
                      sx={chipStyle}
                      onClick={() => window.open(product.link, "_blank")}
                      label={
                        "Rent for $" +
                        product.itemWeeklyPrice.toLocaleString("en-US") +
                        " per week"
                      }
                    />
                  )}
                  {product.itemMonthlyPrice &&
                    product.itemMonthlyPrice !== 0 && (
                      <Chip
                        style={{ marginRight: "1rem" }}
                        sx={chipStyle}
                        onClick={() => window.open(product.link, "_blank")}
                        label={
                          "Rent for $" +
                          product.itemMonthlyPrice.toLocaleString("en-US") +
                          " per month"
                        }
                      />
                    )}
                </>
              )} */}
            </>
          ) : (
            <>
              {userInfo &&
              userInfo.userProducts.findIndex(
                (prod) => prod._id === product._id
              ) > -1 ? (
                <div
                  className="buttons"
                  style={{
                    flexWrap: "wrap",
                  }}
                >
                  {/* {product.itemToSell && product.itemAmount && (
                    <Chip
                      sx={chipStyle}
                      // style={{ marginRight: "1rem" }}
                      // onClick={() => AddToCart(product._id, "sell")}
                      label={`Buy for $${product.itemAmount.toLocaleString(
                        "en-US"
                      )}`}
                    />
                  )} */}
                  {/* {product.itemForRent && (
                    <>
                      {product.itemDailyPrice &&
                        product.itemDailyPrice !== 0 && (
                          <Chip
                            sx={chipStyle}
                            // style={{ marginRight: "1rem" }}
                            // onClick={() => {
                            //   if (product.itemToHold) {
                            //     toast.info(
                            //       "This Equipment is On-Hold and not available currently.",
                            //       toastOption
                            //     );
                            //   } else {
                            //     AddToCart(product._id, "rent");
                            //   }
                            // }}
                            label={
                              "Rent for $" +
                              product.itemDailyPrice.toLocaleString("en-US") +
                              " per day"
                            }
                          />
                        )}
                      {product.itemWeeklyPrice &&
                        product.itemWeeklyPrice !== 0 && (
                          <Chip
                            // style={{ marginRight: "1rem" }}
                            sx={chipStyle}
                            // onClick={() => AddToCart(product._id, "rent")}
                            label={
                              "Rent for $" +
                              product.itemWeeklyPrice.toLocaleString("en-US") +
                              " per week"
                            }
                          />
                        )}
                      {product.itemMonthlyPrice &&
                        product.itemMonthlyPrice !== 0 && (
                          <Chip
                            // style={{ marginRight: "1rem" }}
                            sx={chipStyle}
                            // onClick={() => AddToCart(product._id, "rent")}
                            label={
                              "Rent for $" +
                              product.itemMonthlyPrice.toLocaleString("en-US") +
                              " per month"
                            }
                          />
                        )}
                    </>
                  )} */}
                  {/* <QuantityButton onQuant={onQuant} onRemove={onRemove} onAdd={onAdd} /> */}
                  <Link
                    to={`/editproduct/${product.itemBrand?`${encodeURIComponent(
                      brands.find((brand) => brand._id === product.itemBrand).label
                    )}-`:''}${encodeURIComponent(product.itemName.replace(/\//g, '-'))}`}
                    state={{ id: product._id }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      sx={{
                        ...chipStyle,
                        background: "var(--secondary-color)",
                        ":hover": {
                          background: "var(--grey-color)",
                        },
                      }}
                      label={`Edit Equipment`}
                      icon={
                        <FaPen
                          color="var(--white-color)"
                          style={{ marginRight: ".2rem" }}
                        />
                      }
                    />
                  </Link>
                </div>
              ) : (
                <>
                  {cartVal.includes(product._id) ? (
                    <Link to={"/checkout"} style={{ width: "100%" }}>
                      <Chip
                        sx={{
                          ...chipStyle,
                          background: "var(--secondary-color)",
                          ":hover": {
                            background: "var(--grey-color)",
                          },
                        }}
                        icon={
                          <FaBagShopping
                            color="var(--white-color)"
                            style={{ marginRight: ".2rem" }}
                          />
                        }
                        label={"Go to Cart"}
                      />
                    </Link>
                  ) : (
                    <>
                      {product.itemToSell && product.itemAmount && (
                        <Chip
                          sx={{
                            ...chipStyle,
                            background: "var(--secondary-color)",
                            ":hover": {
                              background: "var(--grey-color)",
                            },
                          }}
                          style={{ marginRight: "1rem" }}
                          onClick={() => AddToCart(product._id, "sell")}
                          label={`Buy for $${product.itemAmount.toLocaleString(
                            "en-US"
                          )}`}
                        />
                      )}
                      {product.itemForRent && (
                        <>
                          {product.itemDailyPrice &&
                          product.itemDailyPrice !== 0 ? (
                            <Chip
                              sx={{
                                ...chipStyle,
                                background: "var(--secondary-color)",
                                ":hover": {
                                  background: "var(--grey-color)",
                                },
                              }}
                              style={{ marginRight: "1rem" }}
                              onClick={() => {
                                if (product.itemToHold) {
                                  toast.info(
                                    "This Equipment is On-Hold and not available currently.",
                                    toastOption
                                  );
                                } else {
                                  AddToCart(product._id, "rent");
                                }
                              }}
                              label={
                                "Rent for $" +
                                product.itemDailyPrice.toLocaleString("en-US") +
                                ""
                                // " per day"
                              }
                            />
                          ) : product.itemWeeklyPrice &&
                            product.itemWeeklyPrice !== 0 ? (
                            <Chip
                              style={{ marginRight: "1rem" }}
                              sx={{
                                ...chipStyle,
                                background: "var(--secondary-color)",
                                ":hover": {
                                  background: "var(--grey-color)",
                                },
                              }}
                              onClick={() => AddToCart(product._id, "rent")}
                              label={
                                "Rent for $" +
                                product.itemWeeklyPrice.toLocaleString(
                                  "en-US"
                                ) +
                                ""
                                // " per week"
                              }
                            />
                          ) : (
                            product.itemMonthlyPrice &&
                            product.itemMonthlyPrice !== 0 && (
                              <Chip
                                style={{ marginRight: "1rem" }}
                                sx={{
                                  ...chipStyle,
                                  background: "var(--secondary-color)",
                                  ":hover": {
                                    background: "var(--grey-color)",
                                  },
                                }}
                                onClick={() => AddToCart(product._id, "rent")}
                                label={
                                  "Rent for $" +
                                  product.itemMonthlyPrice.toLocaleString(
                                    "en-US"
                                  ) +
                                  ""
                                  // " per month"
                                }
                              />
                            )
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>

        <Divider sx={{ mb: "1rem", borderBottomWidth: "medium" }} />
        {/* <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          flexWrap={"wrap"}
        > */}
        <Box
          sx={{ width: { md: "100%", marginTop: "0rem" } }}
          // display={"flex"}
          // flexDirection={"column"}
          // justifyContent={"flex-start"}
          // alignItems={"flex-start"}
        >
          {product.itemGoal && (
            <Typography variant="h5" my={1}>
              <strong>Category: </strong> {product.itemGoal},{" "}
              {product.itemCategoryName}, {product.itemSubCategoryName}
            </Typography>
          )}

          {product.itemCondition && (
            <Typography variant="h5" my={1}>
              <strong>Condition: </strong>{" "}
              {product.itemCondition.replace(/\b\w/g, (match) =>
                match.toUpperCase()
              )}
              , {product.itemHoursUsed} hours used
            </Typography>
          )}

          {!product.merchantFeed && (
            <Typography variant="h5" my={1}>
              <strong>Brand: </strong>{" "}
              {brands.find((brand) => brand._id === product.itemBrand).label},{" "}
              {product.itemYearManufacture}
            </Typography>
          )}

          {product.itemForRent && !product.merchantFeed && (
            <Typography variant="h5" my={1}>
              <strong>Cancellation Policy: </strong>{" "}
              {product.cancellation
                ? `${product.cancellationDate} days before rental period`
                : "72 hours from Booking Date"}
            </Typography>
          )}
          {/* 
          {product.itemTransportationPrice && (
            <Typography variant="h5" my={1}>
              <strong>Transportation Price (upto 25 miles): </strong>{" "}
              {product.itemTransportationPrice}
            </Typography>
          )}

          {product.itemTransportationPrice2 && (
            <Typography variant="h5" my={1}>
              <strong>Transportation Price (upto 50 miles): </strong>{" "}
              {product.itemTransportationPrice2}
            </Typography>
          )}

          {product.fldData &&
            Object.keys(product.fldData).map((key) => (
              <Typography variant="h5" my={1}>
                <strong>{fldMappings[key]}: </strong> {product.fldData[key]}
              </Typography>
            ))} */}
        </Box>

        <Accordion
          defaultExpanded={true}
          className="equipment-details"
          sx={{
            width: { md: "100%" },
            boxShadow: "none",
            background: "var(--black-color)",
            color: "var(--white-color)",
            borderRadius: "10px",
            border: "none",
            borderTop: "none",
            padding: "1rem",
            marginTop: "1rem",
          }}
        >
          <AccordionSummary
            sx={{ borderRadius: "10px", border: "none" }}
            expandIcon={
              <ExpandCircleDown sx={{ color: "var(--grey-color)" }} />
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography
              variant="h4"
              fontWeight={700}
              color={"var(--grey-color)"}
            >
              Attachment Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ borderRadius: "10px", border: "none" }}>
            <strong> {product.itemSubCategoryName}</strong>
            {product.fldData &&
              Object.keys(product.fldData).map((key) => (
                <Typography variant="h5" my={1}>
                  <strong>{fldMappings[key]}: </strong> {product.fldData[key]}
                </Typography>
              ))}

            {product.attachments &&
              product.attachments.length > 0 &&
              product.attachments.map((attch, ind) => (
                <Box marginTop={"1rem"}>
                  <Typography fontWeight={700} color={"var(--white-color)"}>
                    Attachment {ind + 1}
                  </Typography>
                  {Object.keys(attch).map((key) => (
                    <Typography
                      variant="h6"
                      my={0.5}
                      // color={"var(--grey-color)"}
                    >
                      {fldMappings[key]}: {attch[key]}
                    </Typography>
                  ))}
                </Box>
              ))}
          </AccordionDetails>
        </Accordion>
        {/* </Stack> */}
      </section>
    </>
  );
};

export default Description;
